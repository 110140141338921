.card-box {
    $gap: var(--gap-main);
    margin-top: $gap;

    &__wrapper {
        margin-top: ac(60px, 25px);
    }

    &__card {
        border-radius: ac(40px, 30px);
        padding: ac(33px, 20px) ac(35px, 20px) ac(35px, 20px);

        &--pink {
            background-color: var(--pink);
        }
        &--green {
            background-color: var(--green);
        }
        &--blue {
            background-color: var(--blue);
        }
        &--yellow {
            background-color: var(--yellow);
        }
    }

    &__name {
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
    }

    &__descr {
        margin-top: ac(18px, 10px);
    }
}