.vision {
    $gap: var(--gap-main);
    margin-top: $gap;

    &__descr {
        margin-top: ac(30px, 15px);
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
        letter-spacing: .09px;
    }

    @mixin sm {
        &__wrapper {
            display: grid;
        }

        &__wrapper {
            column-gap: ac(128px, 60px, 640, 1440);
            grid-template-columns: min(45%, 520px) min(45%, 520px);
        }
    }

    @mixin max-sm {
        &__col {
            &:not(:last-child) {
                margin-bottom: 35px;
            }
        }
    }
}