.talent {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 41px) 0 ac(110px, 60px);
    background-color: var(--black);
    overflow: hidden;

    &__decor {
        position: absolute;

        img {
            object-fit: contain;
        }
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__img {
        width: 100%;
        flex-shrink: 0;
    }

    &__col {
        color: var(--white);
    }

    &__subtitle {
        margin-top: ac(35px, 20px);
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
    }

    &__descr {
        margin-top: 10px;
        font-size: 17px;
        line-height: ac(21px, 26px);
    }

    @mixin sm {
        &__wrapper {
            display: flex;
        }

        &__img {
            margin-right: 20px;
        }

        &__decor {
            width: 300px;
            right: 20px;
            height: 200px;
            bottom: 65px;
            transform: translateY(100%);
        }
    }

    @mixin md {
        &__decor {
            top: ac(135px, 40px);
            left: 50%;
            transform: translateX(calc(-50% + ac(464px, 400px)));
            width: ac(670px, 400px);
            height: ac(427px, 300px);
        }

        &__title {
            max-width: 304px;
        }

        &__col {
            max-width: ac(520px, 360px);
        }

        &__img {
            max-width: ac(304px, 245px);
            height: ac(477px, 410px);
        }
    }

    @mixin max-md {
        &__img {
            max-width: ac(304px, 200px, 640, 768);
            height: ac(477px, 300px, 640, 768);
        }
    }

    @mixin max-sm {
        &__img {
            max-width: ac(304px, 200px, 320, 640);
            height: ac(477px, 300px, 320, 640);
            margin: 0 auto 20px;
        }
    }
}