.seo {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 41px) 0 ac(110px, 60px);
    background-color: var(--black);
    color: var(--white);

    &__simplebar {
        width: 100%;
        max-height: calc(ac(51px, 30px) * 9);

        .simplebar-scrollbar::before {
            background-color: var(--white);
        }
          
        .simplebar-track {
            background-color: rgba(255, 255, 255, .2);
        }
    }

    &__descr {
        padding-right: 10px;
        font-size: ac(40px, 24px);
        line-height: ac(50px, 30px);
        padding-bottom: ac(1px, 0px, 410, 411);
    }

    &__name {
        margin-top: ac(89px, 25px);
        font-size: ac(40px, 28px);
        line-height: ac(40px, 34px);
        font-family: var(--font-sec);
        font-weight: 600;
    }

    &__pos {
        margin-top: ac(15px, 10px);
        font-size: 17px;
        line-height: 21px;
        font-weight: 700;
    }

    @mixin md {
        &__img {
            max-width: ac(412px, 300px);
            height: ac(615px, 400px);
            margin-right: ac(40px, 20px);
        }

        &__descr {
            letter-spacing: 1.9px;
        }
    }

    @mixin max-md {
        &__img {
            max-width: ac(400px, 300px, 320, 768);
            height: ac(600px, 400px, 320, 768);
            margin: 0 auto 25px;
        }
    }
}