.investing {
    $gap: var(--gap-main);
    margin-top: $gap;
}

.investing--services {
    $gap: var(--gap-sec);
    margin-top: $gap;
}

.investing {
    &__wrapper {
        margin-top: ac(60px, 31px);
        margin-bottom: -30px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: ac(30px, 32px);

        &:hover {
            .investing__img {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

    &__btn {
        margin-top: auto;
    }

    &__img {
        margin-bottom: ac(30px, 12px);
        width: 100%;
        height: ac(160px, 126px, 375, 640);
        overflow: hidden;

        img {
            transition: .3s ease;
            object-position: top;
        }
    }

    &__name {
        margin-bottom: ac(21px, 6px);
        font-size: ac(40px, 32px);
        line-height: ac(40px, 48px);
        font-weight: 600;
        font-family: var(--font-sec);
    }

    &__simplebar {
        width: 100%;
        max-height: calc(6 * ac(21px, 26px));
        margin-bottom: ac(30px, 20px);

        .simplebar-track {
            background-color: rgba(0,0,0, .2);
        }
    }

    &__descr {
        font-size: 17px;
        line-height: ac(21px, 26px);
        padding-right: 10px;
    }

    &--invest {
        .investing {
            &__title {
                max-width: 10ch;
            }
        }
    }

    &--services {
        .investing__img {
            height: ac(200px, 146px, 375, 640);
        }
    }

    @mixin sm {
        &__wrapper {
            margin-right: -20px;
        }

        &__card {
            flex: max(312px, 100% / 3 - 20px);
            margin-right: 20px;
        }

        &--services {
            .investing__card {
                flex: max(312px, 100% / 2 - 20px);
                margin-right: 20px;
            }
        }
    }
}