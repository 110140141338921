.awards {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 49px) 0 ac(110px, 56px);
    background-color: var(--black);

    &__decor {
        top: ac(42px, 168px, 375, 1440);
        width: ac(995px, 1341px, 375, 1440);
        height: ac(635px, 855px, 375, 1440);
        transform: translateX(calc(-50% + ac(430px, 309px, 375, 1440)));
    }

    .swiper-slide:nth-child(even) {
        .awards__card {
            background-color: var(--blue);
        }
    }

    &__title {
        color: var(--white);
    }

    &__card {
        padding: ac(40px, 15px);
        background-color: var(--green);
        border-radius: ac(40px, 32px);
        color: var(--white);
    }

    &__img-box {
        height: ac(201px, 140px);
        padding: 20px;
        border-radius: ac(40px, 32px);
        background-color: var(--white);
    }

    &__img {
        height: 100%;
        border-radius: ac(40px, 32px);

        img {
            width: auto;
            height: 100%;
        }
    }

    &__name {
        margin-top: ac(52px, 42px);
        font-family: var(--font-sec);
        font-size: ac(16px, 14px);
        font-weight: 700;
    }

    &__simplebar {
        margin-top: ac(23px, 13px);
        width: calc(100% + 10px);
        max-height: calc(ac(41px, 35px) * 4);

        .simplebar-scrollbar:before {
            background-color: var(--white);
        }
    }

    &__descr {
        font-size: ac(40px, 28px);
        line-height: ac(40px, 34px);
        font-family: var(--font-sec);
        font-weight: 600;
        padding-bottom: 3px;
        padding-right: 10px;
        font-kerning: none;
    }

    .slider-btn {
        filter: drop-shadow(0px 0px 1px black);
    }

    @mixin sm {
        &__title {
            flex-shrink: 0;
            width: 100%;
            max-width: ac(350px, 175px);
            margin-right: ac(82px, 20px);
        }
    }

    @mixin md {
        .slider-btn {
            margin-bottom: 19px;
        }

        &__card-box {
            flex-shrink: 0;
            overflow: hidden;
        }

        &__slider {
            max-width: ac(600px, 300px);
            margin-left: 0;
        }

        .prev.slider-btn {
            margin-left: 0;
        }

        &__nav {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    @mixin lg {
        &__slider {
            max-width: ac(844px, 690px, 1024, 1440);
        }
    }

    @mixin max-md {
        &__title {
            max-width: 175px;
            margin-right: 0;
        }
        &__nav {
            order: 1;
        }

        .slider-btn {
            margin-top: 19px;
        }

        &__wrapper {
            flex-direction: column;
        }

        &__card-box {
            margin-top: 47px;
        }

        &__card {
            padding: ac(32px, 15px, 375, 768);
        }

        .swiper-slide:nth-child(1) {
            .awards__img {
                width: 126px;
                height: 126px;
            }
        }

        &__img-box {
            height: 158px;
            padding: 16px;
        }

        &__decor {
            display: none;
        }
    }
}