.team {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(109px, 40px) 0 ac(110px, 40px);
    background-color: var(--black);
    overflow: hidden;

    &__name, &__position, &__title {
        color: var(--white);
        transition: .3s ease;
    }

    &__slider {
        margin-top: ac(99px, 30px);
    }

    &__card {
        width: 100%;

        &:hover {
            .team {
                &__name, &__position, &__title {
                    color: var(--pink);
                }
            }
        }
    }

    &__img {
        width: 100%;
        height: ac(502px, 200px, 375, 1440);

        img {
            object-position: top;
        }
    }

    &__name {
        margin-top: ac(30px, 15px);
        font-size: ac(40px, 20px);
        line-height: ac(40px, 26px);
        font-family: var(--font-sec);
        font-weight: 600;
    }

    &__position {
        margin-top: ac(15px, 10px);
        font-size: ac(17px, 15px);
        line-height: 21px;
        font-weight: 700;
    }

    @mixin lg {
        .slider-btn {
            display: none;
        }

        .swiper-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 50px;
            column-gap: 20px;
		}
    }

    @mixin max-lg {
        .slider-btn {
            margin-top: 19px;
    
            &.next {
                margin-right: auto;
            }
        }
    }

    @mixin max-xs {
        &__img {
            height: 300px;
    
            img {
                object-position: top;
            }
        }
    }
    
}