:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

body:has(#popup.active) {
 overflow: hidden;
}

#popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;

  }
}

.popup {
  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    max-height: 70vh;
    padding-right: 10px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--pink) var(--blue);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--blue);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--pink);
    }

    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.video-popup {
  height: ac(500px, 200px, 320, 1440);
  padding: 40px!important;
  min-height: unset !important;

  .popup__title {
    display: none !important;
  }

  .popup__btn-close {
    right: 5px;
    top: 5px;
    z-index: 5;
  }

  .cross-clip {
    width: ac(56px, 35px);
    height: ac(56px, 35px);
    border-radius: 100%;
    overflow: hidden;
    clip-path: none;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='none'%3E%3Cpath fill='%23fff' d='m21.172 38-2.805-2.857 6.828-6.956L18 20.857 20.805 18 28 25.33 35.195 18 38 20.857l-7.195 7.33 6.828 6.956L34.828 38 28 31.044 21.172 38Z'/%3E%3Crect width='55' height='55' x='.5' y='.5' stroke='%23E572DA' rx='27.5'/%3E%3C/svg%3E");
    transition: .3s ease;

    &:hover {
      background-color: var(--pink);

      transform: unset;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    z-index: -1;

    .plyr {
      height: 100%;
    }
    .plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
      background: var(--pink);
    }

    .plyr--full-ui input[type=range] {
      color: var(--pink);
    }

    .plyr__poster {
      background-size: cover;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black);
        opacity: .4;
        z-index: -1;
      }
    }

    video {
      object-fit: cover;
    }

    .plyr__video-wrapper {
      z-index: -1 !important;
    }

    .plyr__control--overlaid {
      margin-left: ac(7px, 4px);
      width: ac(56px, 40px);
      height: ac(56px, 40px);
      border-radius: 0;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23fff' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat;

      svg {
        display: none;
      }

      &:hover {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23E572DA' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat !important;
      }
    }
  }
}

.apply-popup,
.news-popup,
.job-popup {
  width: 90%;
  max-width: 1120px;
  height: auto;
  padding: ac(39px, 30px) ac(64px, 15px) 0px;

  .simplebar-vertical {
    &:nth-child(2 of .simplebar-vertical) {
      display: none ;
    }
  }
  .simplebar-track.simplebar-horizontal {
    display: none;
  }

  .simplebar-track.simplebar-vertical {
    background-color: rgba(158, 171, 184, 0.1);
    border: 0;
    width: 6px;
    border-radius: 0px;
  }

  .simplebar-scrollbar::before {
    border-radius: 0px;
    background: rgba(229, 114, 218, .3);
    opacity: 1 !important;
    top: 0px !important;
    left: 0px;
    right: 0px;
    bottom: 0px !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    padding-right: 40px;
    margin-bottom: ac(39px, 24px);
    font-size: ac(40px, 24px);
    line-height: ac(50px, 32px);
    font-weight: 700;
    font-family: var(--font-sec);
  }

  .popup__btn-close {
    right: ac(40px, 15px);
    top: ac(36px, 25px);
    z-index: 5;
  }

  .cross-clip {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    overflow: hidden;
    clip-path: none;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='none'%3E%3Cpath fill='%231E1E1E' d='m21.172 38-2.805-2.857 6.828-6.956L18 20.857 20.805 18 28 25.33 35.195 18 38 20.857l-7.195 7.33 6.828 6.956L34.828 38 28 31.044 21.172 38Z'/%3E%3Crect width='55' height='55' x='.5' y='.5' stroke='%23E572DA' rx='27.5'/%3E%3C/svg%3E");
    transition: .3s ease;

    &:hover {
      background-color: var(--pink);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='none'%3E%3Cpath fill='%23fff' d='m21.172 38-2.805-2.857 6.828-6.956L18 20.857 20.805 18 28 25.33 35.195 18 38 20.857l-7.195 7.33 6.828 6.956L34.828 38 28 31.044 21.172 38Z'/%3E%3Crect width='55' height='55' x='.5' y='.5' stroke='%23E572DA' rx='27.5'/%3E%3C/svg%3E");
      transform: unset;
    }
  }

  &__text {
    padding-bottom: ac(50px, 20px);
  }

  &__info-box {
    gap: 20px ac(119px, 20px);
    margin-bottom: ac(32px, 20px);
    padding: ac(20px, 15px) 0 ac(24px, 15px);
    border-top: 1px solid #9EABB8;
    border-bottom: 1px solid #9EABB8;
  }

  &__info {
    gap: ac(20px, 10px);
  }

  &__value {
    font-size: ac(25px, 18px);
    line-height: ac(25px, 20px);
    font-weight: 500;
    font-family: var(--font-sec);
    text-transform: capitalize;
  }

  &__bottom {
    position: relative;
    padding: ac(32px, 15px) 0;
    gap: ac(15px, 10px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + ac(126px, 30px));
      height: 1px;
      background-color: #9EABB8;
    }
  }

  &__ava {
    flex-shrink: 0;
    margin-right: ac(16px, 10px);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid var(--pink);
  }

  &__name {
    font-size: ac(20px, 18px);
    line-height: ac(24px, 22px);
    font-weight: 700;
    font-family: var(--font-sec);
  }

  &__pos {
    font-family: var(--font-sec);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  &__sep {
    width: 1px;
    height: 56px;
    background-color: rgba(158, 171, 184, 1);
  }

  &__contacts {
    gap: ac(10px, 5px);
  }

  &__link {
    font-family: var(--font-sec);
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-variant-numeric: lining-nums proportional-nums;
    transition: .3s ease;

    &:hover {
      color: var(--pink);
    }
  }

  &__btn {
    min-width: ac(193px, 120px);
  }

  .popup__title {
    display: none;
  }

  @mixin max-sm {

      .cross-clip {
        width: 35px;
        height: 35px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%231E1E1E' d='m13.232 23.75-1.753-1.786 4.268-4.347-4.497-4.581 1.753-1.786 4.497 4.581 4.497-4.581 1.753 1.786-4.497 4.58 4.268 4.348-1.754 1.786-4.267-4.348-4.268 4.348Z'/%3E%3Cpath stroke='%23E572DA' d='M34.688 17.5C34.688 8.008 26.992.312 17.5.312 8.008.313.312 8.008.312 17.5c0 9.492 7.696 17.188 17.188 17.188 9.492 0 17.188-7.696 17.188-17.188Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h35v35H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' d='m13.232 23.75-1.753-1.786 4.268-4.347-4.497-4.581 1.753-1.786 4.497 4.581 4.497-4.581 1.753 1.786-4.497 4.58 4.268 4.348-1.754 1.786-4.267-4.348-4.268 4.348Z'/%3E%3Cpath stroke='%23E572DA' d='M34.688 17.5C34.688 8.008 26.992.312 17.5.312 8.008.313.312 8.008.312 17.5c0 9.492 7.696 17.188 17.188 17.188 9.492 0 17.188-7.696 17.188-17.188Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23E572DA' d='M0 0h35v35H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
      }

      &__sep {
        width: 100%;
        height: 1px;
      }

      &__btn {
        margin-top: 15px;
      }

      &__text-box {
        .simplebar-track.simplebar-vertical {
          width: 10px;
        }

        .simplebar-scrollbar::before {
          top: 2px !important;
          left: 2px;
          right: 2px;
          bottom: 2px !important;
        }
      }

    &__content {
      padding-right: 20px;
    }
  }

}

.job-popup {
  .popup__content {
    max-height: inherit;
  }
}

.apply-popup {
  width: 90%;
  max-width: 820px;
  height: auto;
  padding: ac(60px, 70px) ac(39px, 15px) 0;

  &>.simplebar-wrapper>.simplebar-mask>.simplebar-offset>.simplebar-content-wrapper>.simplebar-content {
    overflow-x: hidden;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: minmax(0,1fr);
  }

  .popup__content {
    max-height: initial;
  }

  .title-small {
    padding-bottom: 50px;
    font-size: ac(40px, 22px, 320, 1440);
    line-height: ac(40px, 26px, 320, 1440);
  }

  .popup__title {
    display: block;
    margin-bottom: 0;
  }
  .ready__form {
    .ready__upload-btn {
      div {
        border: 1px solid var(--black);
        color: var(--black);
      }

      &:hover {
        div {
          background-color: var(--pink);
        }

        .footer__upload-text {
          color: var(--pink);
        }
      }
    }

    .ready__upload-box {
      max-width: 360px;
      margin-bottom: ac(30px, 20px, 375, 1440);

      &>span:first-child {
        color: var(--black);
      }
    }

    .ready__upload-text {
      color: var(--black);
    }

    .ready__btn {
      margin-top: 0;
      color: var(--black);
    }

    input, textarea {
      border-bottom: 1px solid var(--black);
      @mixin transparent-bg-input var(--black);
      background-color: var(--white);
      margin-bottom: ac(30px, 13px, 375, 1440);

      &,
      &::placeholder {
        color: var(--black);
      }

      &:hover {
        border-color: var(--pink);
        &,
        &::placeholder {
          color: var(--black);
        }
      }
    }
    textarea {
      scrollbar-color: var(--black) rgba(0, 0, 0, .2);
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, .2);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--black);
      }
    }
  }
}


.news-popup {
  &--blue {
    .news-popup__title {
      span {
        &::after {
          background-color: var(--blue);
        }
      }
    }
  }
  &--pink {
    .news-popup__title {
      span {
        &::after {
          background-color: var(--pink);
        }
      }
    }
  }
  &--green {
    .news-popup__title {
      span {
        &::after {
          background-color: var(--green);
        }
      }
    }
  }
  &--yellow {
    .news-popup__title {
      span {
        &::after {
          background-color: var(--yellow);
        }
      }
    }
  }

  &__date {
    font-family: var(--font-sec);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__title {
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 24px;

    display: inline-flex;
    flex-wrap: wrap;
    overflow: hidden;

    span {
      position: relative;
      margin-right: 6px;

      &::after {
        content: '';
        position: absolute;
        bottom: 9px;
        right: 0;
        height: ac(3px, 2px);
        width: calc(100% + 7px);
        z-index: -1;
        transition: .3s ease;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + ac(126px, 30px));
      height: 1px;
      background-color: #9EABB8;
    }
  }

  &__text-box {
    &:before {
      display: none;
    }
  }

  &__btn-box {
    gap: 12px;
  }

  &__btn {
    min-width: 106px;
  }

  &__share-box {
    gap: 12px;

    span {
      margin-right: 8px;
      font-size: ac(25px, 20px);
      line-height: 25px;
      font-family: var(--font-sec);
      font-weight: 500;
    }
  }

  &__soc {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 100%;
    transition: .3s ease;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      background-color: var(--pink);
    }

    &--f {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%231E1E1E' d='M21.25 19.833H18.5V23.5h2.75v11h4.583v-11h3.339l.328-3.667h-3.667v-1.528c0-.875.176-1.222 1.022-1.222H29.5V12.5h-3.49c-3.297 0-4.76 1.451-4.76 4.23v3.103Z'/%3E%3Crect width='47' height='47' x='.5' y='.5' stroke='%23E572DA' rx='23.5'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23fff' d='M21.25 19.833H18.5V23.5h2.75v11h4.583v-11h3.339l.328-3.667h-3.667v-1.528c0-.875.176-1.222 1.022-1.222H29.5V12.5h-3.49c-3.297 0-4.76 1.451-4.76 4.23v3.103Z'/%3E%3Crect width='47' height='47' x='.5' y='.5' stroke='%23E572DA' rx='23.5'/%3E%3C/svg%3E");
      }
    }

    &--in {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%231E1E1E' d='M17.858 14.391c0 1.321-1.05 2.392-2.347 2.392-1.296 0-2.347-1.07-2.347-2.392 0-1.32 1.05-2.391 2.347-2.391 1.296 0 2.347 1.071 2.347 2.391Zm.018 4.305h-4.73V34h4.73V18.696Zm7.553 0h-4.7V34h4.701v-8.034c0-4.467 5.705-4.832 5.705 0V34h4.72v-9.69c0-7.538-8.442-7.263-10.426-3.553v-2.061Z'/%3E%3Crect width='47' height='47' x='.5' y='.5' stroke='%23E572DA' rx='23.5'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23fff' d='M17.858 14.391c0 1.321-1.05 2.392-2.347 2.392-1.296 0-2.347-1.07-2.347-2.392 0-1.32 1.05-2.391 2.347-2.391 1.296 0 2.347 1.071 2.347 2.391Zm.018 4.305h-4.73V34h4.73V18.696Zm7.553 0h-4.7V34h4.701v-8.034c0-4.467 5.705-4.832 5.705 0V34h4.72v-9.69c0-7.538-8.442-7.263-10.426-3.553v-2.061Z'/%3E%3Crect width='47' height='47' x='.5' y='.5' stroke='%23E572DA' rx='23.5'/%3E%3C/svg%3E");
      }
    }

    &--tw {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Crect width='47' height='47' x='.5' y='.5' stroke='%23E572DA' rx='23.5'/%3E%3Cpath fill='%231E1E1E' d='m13.056 13 8.88 12.134L13 35h2.011l7.824-8.638L29.155 35H36l-9.38-12.817L34.938 13h-2.011l-7.205 7.955L19.9 13h-6.844Zm2.958 1.514h3.144l13.884 18.972h-3.144L16.014 14.514Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Crect width='47' height='47' x='.5' y='.5' stroke='%23E572DA' rx='23.5'/%3E%3Cpath fill='%23fff' d='m13.056 13 8.88 12.134L13 35h2.011l7.824-8.638L29.155 35H36l-9.38-12.817L34.938 13h-2.011l-7.205 7.955L19.9 13h-6.844Zm2.958 1.514h3.144l13.884 18.972h-3.144L16.014 14.514Z'/%3E%3C/svg%3E");
      }
    }
  }

  @mixin max-sm {
    &__share-box {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      span {
        margin-right: 0;
        width: 100%;
        text-align: center;
      }
    }

    &__btn-box {
      width: 100%;
    }
  }
}

.agreement {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .checkBox {
    display: flex;
    position: relative;
    width: 100%;

    input {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
    }
    input:checked + span:before {
      background: var(--pink);
    }

    span {
      padding-left: ac(35px, 30px);
      margin-bottom: 0;
      color: var(--black);
      cursor: pointer;

      a {
        display: inline-block;
        color: var(--pink);
        text-decoration: underline;

        &:hover {
          color: var(--blue);
        }
      }

      &::before {
        content: '';
        background: transparent;
        border: 1px solid var(--black);
        box-sizing: border-box;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: 1px;
        transition: all .3s ease-out;
        width: 20px;
        cursor: pointer;
      }
    }
  }
}
