.testimonials {
    $gap: var(--gap-main);
    margin-top: $gap;
    overflow: hidden;

    &__slider {
        width: calc(100% + 6px);
        margin-top: ac(58px, 30px);
    }

    &__card {
        position: relative;
        padding: 13px 16px 13px 0;
        border-right: 1px solid var(--black);
    }

    &__icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: ac(40px, 30px);
        height: ac(40px, 30px);

        img {
            object-fit: contain;
        }
    }

    &__name {
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
    }

    &__stars {
        margin-top: 11px;
        width: 88px;
        height: 15px;

        img {
            object-fit: contain;
        }
    }

    &__date, &__descr,  &__link {
        font-size: 17px;
        line-height: 21px;
    }

    &__date {
        margin-top: 8px;
    }

    &__descr {
        margin-top: ac(16px, 10px);
    }

    &__date, &__link {
        font-weight: 700;
    }

    &__link {
        margin-top: 20px;
        opacity: .5;
        transition: .3s ease;

        &:hover {
            opacity: 1;
            color: var(--pink);
        }
    }

    @mixin md {
        &__wrapper {
            align-items: flex-end;
        }

        &__title {
            max-width: 13ch;
        }

        .slider-btn {
            margin-bottom: 11px;
        }
    }

    @mixin max-md {
        .slider-btn {
            order: 1;
            margin-top: 16px;
        }
    }
}