.sectors {
    $gap: var(--gap-sec);
    margin-top: $gap;

    &+.sectors {
        margin-top: ac(80px, 40px);

        .about__decor {
            display: none;
        }
    }

    &--alt {
        .sectors {
            &__img, &__video {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    &__title {
        margin-bottom: ac(60px, 30px);
    }

    &__img, &__video {
        max-width: ac(700px, 300px);
        height: ac(350px, 150px);
    }

    &__col, &__img, &__video {
        width: 100%;
    }

    &__name {
        margin-bottom: ac(20px, 10px);
        font-size: ac(40px, 28px);
        line-height: ac(40px, 34px);
        font-family: var(--font-sec);
        font-weight: 600;
    }

    &__subtitle {
        margin-bottom: 10px;
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
        letter-spacing: .1px;
    }

    &__simplebar {
        width: calc(100% + 10px);
        max-height: calc(21px * 6);

        .simplebar-scrollbar::before {
            background-color: var(--black);
        }
        
        .simplebar-track {
        background-color: rgba(0, 0, 0, .2);
        }
    }

    &__descr {
        padding-right: 10px;
        font-size: 17px;
        line-height: 21px;
    }

    &__btn {
        margin-top: ac(30px, 20px);
    }

    &__video {
        .plyr {
            height: 100%;
        }

        .plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
            background: var(--pink);
        }

        .plyr--full-ui input[type=range] {
            color: var(--pink);
        }

        .plyr__poster {
            background-size: cover;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--black);
                opacity: .4;
                z-index: -1;
            }
        }

        video {
            object-fit: cover;
        }

        .plyr__video-wrapper {
            z-index: -1 !important;
        }

        .plyr--stopped {
            .plyr__controls {
                opacity: 0;
            }
        }

        .plyr__control--overlaid {
            margin-left: ac(7px, 4px);
            width: 47px;
            height: 55px;
            border-radius: 0;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23fff' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat;

            svg {
                display: none;
            }

            &:hover {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23E572DA' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat !important;
            }
        }
    }

    @mixin md {
        &__col {
            padding-top: ac(10px, 0px);
            max-width: ac(520px, 400px);
        }

        &__img, &__video {
            margin-right: auto;
        }
        
        &--alt {
            .sectors__wrapper {
                flex-direction: row-reverse;
            }
        }
    }

    @mixin max-md {
        &__col {
            margin-top: 20px;
        }

        &__img, &__video {
            max-width: ac(600px, 300px, 320, 768);
            height: ac(300px, 150px, 320, 768);
        }
    }
}