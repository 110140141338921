.counter {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 41px) 0 ac(110px, 60px);
    background-color: var(--black);
    color: var(--white);

    &__wrapper {
        margin-top: ac(81px, 36px);
        margin-right: -20px;
        margin-bottom: -21px;
    }

    &__card {
        width: calc(100% / 6 - 20px);
        margin-right: 20px;
        margin-bottom: 21px;
    }

    &__count {
        display: inline-flex;
        align-items: center;
        font-size: ac(70px, 42px);
        line-height: ac(65px, 56px);
        font-weight: 700;
        font-family: var(--font-sec);
    }

    &__symbol {
        font-size: ac(40px, 42px);
        margin-bottom: ac(-20px, 0px);
    }

    &__descr {
        margin-top: ac(30px, 11px);
    }
    @mixin max-lg {
        &__card {
            width: calc(100% / 3 - 20px);
        }
    }

    @mixin max-sm {
        &__wrapper {
            padding: 0 8px;
            margin-right: -16px;
        }
        
        &__card {
            width: calc(100% / 2 - 16px);
            margin-right: 16px;
        }

        &__descr {
            font-size: 16px;
            line-height: 24px;
        }
    }
}