.fp-watermark {
  display: none;
}

html {
	font-size: ac(20px, 17px);
	line-height: ac(24px, 26px);
	font-family: var(--font-main);

	scrollbar-width: thin;
	scrollbar-color: var(--black) rgba(30, 30, 30, .2);

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(30, 30, 30, .2);
		border-radius: 0;
	}
	&::-webkit-scrollbar-thumb {
		background: var(--black);
		border-radius: 0;
	}
}

body {
  min-width: 320px;
  background: var(--white);
  color: var(--black);
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100 * var(--vh));
  overflow: hidden;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: calc(1276px + 48px);
  padding: 0 24px;
}

section {
  position: relative;
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;
  margin-left: auto;
  order: 1;
 
  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin sm {
    transform: translateY(-100%);
    transform-origin: 50% 0%;

    &:hover {
      transform: scale(1.1) translateY(-100%);
    }
  }

  @mixin max-sm {
    margin-right: auto;
    margin-top: 20px;
  }
}

.simplebar-track.simplebar-vertical {
  width: 6px;
}

.simplebar-scrollbar::before {
  border-radius: 0;
  opacity: 1 !important;
  top: 0 !important;
  left: 0;
  bottom: 0 !important;
  right: 0;
  background-color: var(--black);
}

.simplebar-track {
  background-color: rgba(255, 255, 255, .2);
  border-radius: 0;
}

.swiper {
  width: 100%;
  overflow: visible;
}

.swiper-wrapper {
  left: 0 !important;
}

.swiper-slide {
  height: auto;
}

img, video, svg {
  width: 100%;
  height: 100%;
} 

img, video {
  object-fit: cover;
}

svg {
  object-fit: contain;
}

/* 
#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

 */
.grecaptcha-badge {
  z-index: 1111;
}

.single-page-404 {
    min-height: 100vh;
    padding: 20px;
    background-color: var(--black);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__decor {
      position: absolute;
      bottom: ac(50px, -30px, 375, 1440);
      right: -100px;
      width: 90vw;
      height: 30vh;
      max-width: 853px;
      max-height: 343px;
    }

    h1,
    p {
      position: relative;
      z-index: 2;
      margin-bottom: 15px;
      padding: 0;
      text-align: center;
    }

    a {
      z-index: 2;
        margin-top: 20px;
    }
}


.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    background: var(--white) !important;
  }

  #strictly-necessary_label, .is-inactive {
    color: var(--black) !important;
  }

  .cc-pc-head-close {
    color: var(--white) !important;

    &:hover {
      color: var(--pink) !important;
    }
  }

  .cc-cp-body-tabs-item {
    background: var(--black) !important;

    button {
      color: var(--white) !important;
    }
  }

  .cc-cp-body-tabs-item[active=true] {
    background: var(--white) !important;

    button {
      color: var(--black) !important;
      box-shadow: unset !important;
    }
  }

  .cc-cp-foot, .cc-pc-head {
    background: var(--black) !important;
  }

  .cc-cp-body-content {
    background: var(--white) !important;
  }

  .cc-cp-body-content-entry-title, .cc-cp-body-content-entry-text {
    color: var(--black) !important;

    a {
      color: var(--black) !important;
    }
  }

  .cc-cp-foot-byline, .cc-pc-head-title-headline, .cc-pc-head-title-text {
    color: var(--white) !important;
  }

  .cc-cp-foot-save {
    margin-bottom: 0 !important;
    display: inline-flex;
    padding: 0 ac(20px, 19px);
    border: 1px solid var(--pink);
    background-color: var(--white);
    border-radius: 23px;
    font-family: var(--font-sec) !important;
    font-weight: 700;
    font-size: ac(16px, 14px);
    line-height: ac(44px, 38px);
    justify-content: center;
    transition: .3s ease;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: var(--pink);
      color: var(--white);
    }
  }

  @mixin max-sm {
    .cc-cp-foot {
      flex-direction: column;
      padding-bottom: 20px
    }
  }
}
@media only screen and (max-width: 480px) {
  .termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
    justify-content: space-between !important;
  }

  .cc-nb-buttons-container button {
    width: auto !important;
  }

  .termsfeed-com---palette-dark.termsfeed-com---nb .cc-nb-main-container {
    padding: 1.5em 1rem !important;
  }
}
.cc-nb-text, .cc-pc-head-title-text, .cc-pc-head-title-headline, .cc-cp-foot-save, .cc-cp-foot-byline, .cc-cp-body-tabs-item-link, .cc-cp-body-content-entry > *, .cc-custom-checkbox>label {
  font-family: var(--font-main) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select {
  width: 100%;
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  background: var(--white) !important;
  border: 1px solid var(--pink);
  border-radius: 23px 0 0 0;

  .cc-nb-main-container {
    padding: 1.5em 2rem !important;
  }

  .cc-nb-title, .cc-nb-text {
    color: var(--black) !important;
  }

  .cc-nb-title {
    font-family: var(--font-sec) !important;
  }

  .cc-nb-text {
    margin-bottom: 0;
  }

  .cc-nb-buttons-container {
    button {
      margin-bottom: 0 !important;
      margin-top: 13px !important;
      display: inline-flex;
      padding: 0 ac(20px, 19px);
      border: 1px solid var(--pink);
      border-radius: 23px;
      background-color: var(--white);
      font-family: var(--font-sec) !important;
      font-weight: 700;
      font-size: ac(16px, 14px);
      line-height: ac(44px, 38px);
      justify-content: center;
      transition: .3s ease;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: var(--pink);
        color: var(--white);
      }

      &.cc-nb-changep {
        background-color: var(--pink);
        color: var(--white);

        &:hover {
          background-color: var(--white);
          color: var(--black);
        }
      }
    }
  }
}


@mixin max-xs {
  .cont {
    padding: 0 15px;
  }
}