.values {
    $gap: var(--gap-main);
    margin-top: $gap;
    overflow: hidden;

    &__slider {
        margin-top: ac(60px, 25px);
    }

    &__card {
        border: 1px solid var(--black);
        padding: 25px;
        height: 100%;
        padding: ac(44px, 30px) ac(15px, 5px) ac(24px, 15px) ac(24px, 15px);
    }

    &__logo {
        font-size: 130px;
        font-weight: 700;
        line-height: 1em;
        font-family: var(--font-sec);

        &--green {
            color: var(--green);
            margin-left: -3px;
        }

        &--pink {
            color: var(--pink);
        }

        &--blue {
            color: var(--blue);
        }

        &--yellow {
            color: var(--yellow);
            margin-left: -3px;
        }
    }

    &__name {
        margin-top: ac(35px, 20px);
        font-size: ac(25px, 20px);
        line-height:1em;
    }

    &__simplebar {
        width: 100%;
        max-height: calc(10 * ac(21px, 26px));
        margin-top: ac(20px, 10px);

        .simplebar-track {
            background-color: rgba(0,0,0, .2);
        }
    }

    &__descr {
        font-size: 17px;
        line-height: ac(21px, 26px);
        padding-right: 10px;
    }

    .slider-btn {
        margin-top: 40px;

        &.next {
            margin-right: auto;
        }
    }

    @mixin md {
        &__title {
            max-width: 14ch;
        }
    }

}