.hero {
    position: relative;
    z-index: 1;
    overflow: hidden;
    
    &--main {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(30, 30, 30, .6);
        }

        .hero__suptitle {
            padding: ac(2px, 0px) ac(15px, 21px);
            margin-bottom: ac(40px, 26px);
            font-size: ac(20px, 14px);
            font-family: var(--font-sec);
            background-color: var(--pink);
            font-weight: 600;
            border-radius: 14px;
        }
    }

    &--about {
        background-color: var(--black);

        .hero__subtitle {
            max-width: ac(520px, 470px, 1024, 1440);
        }

        .hero__bg {
            max-width: 844px;
            width: calc(100% - 20px);
            max-height: 711px;
            height: calc(100% - 100px);
            top: unset;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;

            img {
                object-position: top;
            }
        }
    }

    &--invest,
    &--services,
    &--recrut {
        .hero__decor {
            bottom: 50%;
            transform: translate(calc(ac(660px, 256px, 375, 1440) - 50%), calc(50% - 103px));
        }

        .hero__text {
            text-align: right;
        }
    }

    &--recrut {
        .hero__text {
            margin-left: auto;
            max-width: 10ch;
        }
    }
    
    &:not(.hero--main) {
        .hero__bg {
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40vh;
                max-height: 368px;
                opacity: 0.6;
                background: linear-gradient(180deg, rgba(30, 30, 30, 0.00) 0%, #1E1E1E 79.25%);
            }
        }
    }

    &__bg, &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__decor {
        position: absolute;
        bottom: ac(214px, 475px, 375, 1440);
        left: 50%;
        transform: translateX(calc(ac(660px, 256px, 375, 1440) - 50%));
        width: ac(853px, 476px, 375, 1440);
        height: ac(343px, 191px, 375, 1440);

        svg {
            object-fit: contain;
        }
    }

    &__wrapper {
        position: relative;
        min-height: 100vh;
        padding: ac(150px, 80px) 0 ac(78px, 85px);
        color: var(--white);
        z-index: 1;
    }

    &__title {
        max-width: 640px;
        margin-bottom: ac(30px, 15px);
    }
    
    &__subtitle {
        max-width: 520px;
    }

    @mixin lg {
        &--invest {
            .hero__title {
                max-width: 740px;
            }
        }

        &__text {
            position: absolute;
            bottom: 67px;
            right: 0px;
        }
        
    }

    @mixin xxl {
        &--about {
            .hero__bg {
                max-height: 80vh;
                max-width: 70vw;
            }
        }
    }

    @media (max-width: 1024px) and (min-height: 1000px) {
        &__wrapper {
            padding-bottom: 30vh;
        }

        &__text {
            bottom: 20vh;
        }

        &__decor {
            bottom: 60vh;
        }

        &:not(.hero--main) {
            .hero__bg {
                &::before {
                    height: 80vh;
                    max-height: unset;
                }
            }
        }
    }

    @mixin max-lg {
        &__text {
            margin-bottom: 33px;
            width: 100%;
            text-align: right;
        }
    }

    @mixin max-xs {
        &--main {
            .hero__suptitle {
                padding: 0 10px;
                font-size: 13px;
            }
        }

        &__decor {
            transform: translateX(calc(236px - 50%));
        }
    }

}