.brands {
    $gap: var(--gap-main);
    margin-top: $gap;

    &__title {
        margin-bottom: ac(50px, 15px);
    }

    &__subtitle {
        margin-bottom: ac(80px, 39px);
    }

    &__card-box {
        margin-right: ac(-56px, -60px, 1024, 1440);
        padding: 0 ac(18px, 8px, 1024, 1440);
        margin-bottom: -20px;
    }

    &__card {
        margin-bottom: 20px;
        width: calc(100% / 6 - ac(56px, 60px, 1024, 1440));
        margin-right: ac(56px, 60px, 1024, 1440);
    }

    &__logo {
        height: 58px;
    }

    &__name {
        margin-top: ac(31px, 9px);
        text-align: center;
        font-size: ac(16px, 14px, 1024, 1440);
        line-height: .9em;
        font-weight: 700;
        font-family: var(--font-sec);
    }

    @mixin sm {
        &__subtitle {
            max-width: 520px;
        }
    }

    @mixin lg {
        &__card {
            white-space: nowrap;

            .btn {
                padding: 0 ac(20px, 9px);
            }
        }
    }

    @mixin max-lg {
        &__card-box {
            margin-right: -20px;
        }

        &__card {
            width: calc(100% / 3 - 20px);
            margin-right: 20px;

            &:nth-child(3) {
                .brands__logo {
                    padding: 7px 3px;
                }
            }
        }

        &__logo {
            padding: 7px 10px;
        }
    }

    @mixin max-sm {
        &__card {
            width: calc(100% / 2 - 20px);
        }

        &__name {
            line-height: 20px;
        }
    }
    
}