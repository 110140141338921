.news {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 41px) 0 ac(103px, 64px);
    background-color: var(--black);

    &__title {
        max-width: calc(100% - 150px);
    }

    &__title {
        color: var(--white);
    }

    &__slider {
        margin-top: ac(40px, 32px);
    }

    &__slide {
        &:nth-child(4n + 1) {
            .news__card {
                .news__name {
                    span {
                        &::after {
                            background-color: var(--blue);
                        }
                    }
                }

                &:hover {
                    .news__name, .news__date, .news__more {
                        color: var(--blue);
                    }
                    .news__name {
                        span {
                            &::after {
                                background-color: var(--white);
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(4n + 2) {
            .news__card {
                .news__name {
                    span {
                        &::after {
                            background-color: var(--yellow);
                        }
                    }
                }

                &:hover {
                    .news__name, .news__date, .news__more {
                        color: var(--yellow);
                    }
                    .news__name {
                        span {
                            &::after {
                                background-color: var(--white);
                            }
                        }
                    }
                }
            }
        }
        &:nth-child(4n + 3) {
            .news__card {
                .news__name {
                    span {
                        &::after {
                            background-color: var(--green);
                        }
                    }
                }

                &:hover {
                    .news__name, .news__date, .news__more {
                        color: var(--green);
                    }
                    .news__name {
                        span {
                            &::after {
                                background-color: var(--white);
                            }
                        }
                    }
                }
            }
        }
        &:nth-child(4n + 4) {
            .news__card {
                .news__name {
                    span {
                        &::after {
                            background-color: var(--pink);
                        }
                    }
                }

                &:hover {
                    .news__name, .news__date, .news__more {
                        color: var(--pink);
                    }
                    .news__name {
                        span {
                            &::after {
                                background-color: var(--white);
                            }
                        }
                    }
                }
            }
        }
    }

    &__card {
        color: var(--white);
        font-family: var(--font-sec);
        cursor: pointer;
    }

    &__date, &__name, &__more {
        transition: .3s ease;
    }

    &__img {
        margin-bottom: 12px;
        height: ac(250px, 200px, 375, 1440);
    }

    &__date {
        margin-bottom: ac(8px, 2px);
        font-family: var(--font-sec);
        font-size: 14px;
    }

    &__name {
        margin-bottom: 14px;
        font-size: ac(25px, 22px);
        line-height: ac(25px, 28px);

        span {
            position: relative;
            margin-right: 6px;

            &::after {
                content: '';
                position: absolute;
                bottom: ac(2px, 4px);
                right: 0;
                height: ac(3px, 2px);
                width: calc(100% + 7px);
                z-index: -1;
                transition: .3s ease;
            }
        }
    }

    &__more {
        margin-top: auto;
        font-weight: 700;
        font-size: 16px;
    }

    .slider-btn {
        align-self: center;
    }

    @mixin max-md {
        .slider-btn {
            order: 1;
            margin-top: 40px;

            &.next {
                margin-right: auto;
            }
        }
    }

    @mixin max-sm {
        &__title {
            width: 100%;
            max-width: unset;
        }

        &__slider {
            padding-right: ac(0px, 84px, 375, 640);
        }
    }
}