.businesses {
    $gap: var(--gap-main);
    margin-top: $gap;
    z-index: 1;
    overflow: hidden;
   
    &__wrapper {
        padding-bottom: ac(48px, 0px);
    }

    &__title {
        margin-bottom: ac(79px, 25px);
    }

    &__text-box {
        padding: ac(60px, 25px, 375, 1440) ac(60px, 20px, 375, 1440);
        background-color: var(--black);
    }

    &__simplebar {
        width: calc(100% + 10px);
        max-height: calc(7 * ac(24px, 26px));

        .simplebar-scrollbar::before {
            background-color: var(--white);
        }
    }

    &__descr {
        padding-right: 10px;
        padding-bottom: 1px;

        &>* {
            &:not(:last-child) {
                margin-bottom: ac(10px, 5px);
            }
        }
    }

    &__btn, &__descr {
        color: var(--white);
    }

    &__btn {
        margin-top: ac(29px, 20px);
    }

    &--alt {
        .businesses {
            &__title {
                margin-left: auto;
            }
        }
    }

    @mixin sm {
    
        &__title {
            min-height: calc(2 * ac(80px, 52px));
            max-width: 10ch;
        }
        
        &__text-box {
            max-width: ac(844px, 600px);
            margin-left: ac(108px, 0px);
        }

        &:not(.businesses--alt) {
            .businesses__img {
                right: ac(2px, 0px);
            }
        }

        &__img {
            position: absolute;
            top: ac(43px, 0px);
            width: ac(734px, 250px);
            height: min(100%, ac(560px, 180px));
            z-index: -1;
        }

        &--alt {
            .businesses__title {
                max-width: ac(490px, 290px, 640, 1440);
            }

            .businesses__img {
                left: 0;
            }

            .businesses__text-box {
                margin-right: ac(108px, 0px);
                margin-left: auto;
            }
        }
    }

    @mixin md {
        &__wrapper {
            min-height: ac(603px, 400px, 1200, 1440);
        }
    }

    @mixin max-sm {
        &__img {
            margin-bottom: 15px;
            height: 250px;
            width: 100%;

            img {
                object-position: top;
            }
        }

        &--alt {
            .businesses__title {
                margin-left: auto;
                text-align: right;
            }
        }
    }
}