.members {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 49px) 0 ac(109px, 56px);
    background-color: var(--black);

    &__text-box {
        color: var(--white);
    }

    &__subtitle {
        margin-top: ac(50px, 25px);
    }

    &__descr {
        margin-top: 10px;
        font-size: 17px;
        line-height: ac(21px, 26px);
    }

    &__btn-box {
        margin-top: ac(30px, 15px);
        gap: ac(18px, 15px);
    }

    &__col {
        gap: ac(20px, 15px);
        grid-auto-rows: 1fr;
        justify-items: center;
    }

    &__card {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 190px;
        padding: ac(30px, 20px) ac(30px, 20px) ac(37px, 20px);
        background-color: var(--green);
        border-radius: ac(40px, 30px);
        overflow: hidden;

        &--img {
            padding: 0;
            justify-content: center;
            position: relative;
        }

        &--yellow {
            background-color: var(--yellow);
        }

        &--pink {
            background-color: var(--pink);
        }

        &--white {
            background-color: var(--white);
        }

        &--blue {
            background-color: var(--blue);
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__count {
        margin-top: auto;
        text-align: right;
        font-weight: 700;
        font-family: var(--font-sec);
    }

    &__num {
        font-size: ac(80px, 52px);
        line-height: ac(80px, 56px);
    }

    &__symbol {
        font-size: ac(40px, 32px);
        margin-bottom: ac(-20px, 0px);
    }

    &__text {
        font-size: 20px;
        margin-bottom: ac(15px, 0px);
    }
    
    @mixin sm {
        &__col {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @mixin md {
        &__text-box {
            max-width: ac(500px, 300px);
        }

        &__descr, &__subtitle {
            max-width: 470px;
        }

        &__wrapper {
            display: flex;
            align-items: flex-start;
        }

        &__text-box {
            flex-shrink: 0;
            margin-right: ac(148px, 25px);
        }
    }

    @mixin max-md {
        &__col {
            margin-top: 25px;
        }
    }

    @mixin max-sm {
       &__card {
        max-width: 300px;
       }
    }
}