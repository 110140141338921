.text-box {
    $gap: var(--gap-sec);
    margin-top: $gap;

    &__descr {
        margin-top: ac(137px, 25px);
        
        &>*:first-child {
            margin-bottom: 10px;
        }

        &>*:not(:first-child) {
            font-size: 17px;
            line-height: 21px;
            margin-top: 5px;
        }
    }

    @mixin md {
        &__title {
            max-width: ac(628px, 350px);
        }
    }
}