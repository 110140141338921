h1, .h1,
h2, .h2,
h3,
h4, h5, h6 {
  font-weight: 700;
  font-family: var(--font-sec);
}

h1, .h1 {
  font-size: ac(100px, 50px);
  line-height: ac(95px, 56px);
}

h2, .h2 {
  font-size: ac(80px, 42px);
  line-height: ac(80px, 52px);
}

h3 {
  font-size: ac(40px, 32px);
  line-height: ac(40px, 48px);
}

a {
  display: inline-block;
}

@mixin max-xs {
    h1, .h1 {
      font-size: 44px;
      line-height: 50px;
    }

    h2 {
      font-size: 38px;
      line-height: 46px;
    }
}

/* h3 {
  font-size: ac(25px, 22px);
  line-height: normal;
  font-weight: 600;
}

h4 {
  font-size: ac(20px, 18px);
  line-height: 140%;
  font-weight: 400;

  @mixin max-sm {
    line-height: 155%;
  }
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

li {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
} */

.content-element {
  h1 {
    margin-top: .8em;
    margin-bottom: .4em;
  }

  h2 {
    &:not(:first-child) {
      margin-top: ac(40px, 25px);
    }
    &:not(:last-child) {
      margin-bottom: ac(34px, 20px);
    }
  }

  h3 {
    &:not(:first-child) {
      margin-top: ac(40px, 20px);
    }
    &:not(:last-child) {
      margin-bottom: ac(24px, 15px);
    }
    font-size: ac(32px, 26px);
    line-height: ac(44px, 34px);
  }

  h4,h5,h6 {
    &:not(:first-child) {
      margin-top: ac(24px, 20px);
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  h4 {
    font-size: ac(30px, 24px);
    line-height: ac(40px, 32px);
  }

  h5 {
    font-size: ac(26px, 20px);
    line-height: ac(40px, 30px);
  }

  h6 {
    font-size: ac(20px, 18px);
    line-height: ac(30px, 24px);
    font-family: var(--font-main);
  }

  &>ul, &>ol {
    &:not(:last-child) {
      margin-bottom: ac(30px, 15px);
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ac(12px, 5px);
    }

  }

  p, li {
    font-size: ac(20px, 16px);
    line-height: ac(32px, 22px);
    font-weight: 400;

    a {
      display: inline-block;
      color: var(--pink);
      text-decoration: underline;
      word-break: break-word;
      font-weight: 400;
      transition: .3s ease;

      &:hover {
        text-decoration: none;
      }
    }
  }

  ul, ol {
    &:not(:first-child) {
      margin-top: 16px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: ac(14px, 5px);
      }
    }
  }

  ul {
    &>li {
      position: relative;
      padding-left: 15px;

      &::before {
        content: '';
        position: absolute;
        top: ac(13px, 8px);
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #3AD0EF;
      }
    }
  }

  ol {
    counter-reset: new-counter;
    list-style: none;

    li {
      counter-increment: new-counter;

      &:before {
        content: counter(new-counter);
        display: inline-flex;
        justify-content: center;
        align-items: center;

        margin-right: ac(7px, 5px);
        width: ac(32px, 28px);
        height: ac(32px, 28px);
        transform: translateY(ac(-2px, 0px));
        border-radius: 100px;
        border: 1px solid #3AD0EF;

        font-size: ac(17px, 15px);
        line-height: ac(21px, 19px);
        font-weight: 400;
      }
    }
  }

  img {
    margin-top: ac(20px, 15px);
    height: auto;
    width: auto;
  }
}