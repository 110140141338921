.animals {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 41px) 0 ac(110px, 60px);
    background-color: var(--black);
    overflow: hidden;

    &__title {
        color: var(--white);
    }

    .slider-btn {
        margin-top: 16px;
    }

    &__slider {
        margin-top: ac(60px, 25px);
    }

    &__card {
        color: var(--white);
    }

    &__img {
        position: relative;
        width: 100%;
        height: ac(460px, 300px);
    }

    &__simplebar {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: 15px 0 15px 15px;
        width: calc(100% - 40px);
        max-height: calc((ac(24px, 26px) * 8) + 30px);
        transition: .3s ease;
        background-color: var(--white);

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 15px;
            background: white;
            z-index: 1;
        }
        
        .simplebar-track {
            background-color: rgba(0,0,0,.2);
        }

        &:not(.is-active) {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__hidden {
        color: var(--black);
        padding-right: 15px;

    }

    &__name {
        font-family: var(--font-sec);
        margin-top: ac(18px, 10px);
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
    }

    &__descr {
        margin-top: 13px;
    }

    &__descr, &__str, &__weak {
        font-size: 17px;
        line-height: ac(21px, 26px);
        font-weight: 700;
    }

    &__bottom {
        margin-top: 13px;
    }

    &__str, &__weak {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        
        &>* {
            transition: color .3s ease;
        }

        &:hover {
            color: var(--pink);

            &::before {
                color: var(--pink);
            }
        }

        &::before {
            content: '+';
            display: inline-block;
            margin-right: 5px;
            transition: .3s ease;
        }

        &.is-active {
            &::before {
                content: '-';
                color: var(--pink);
            }
        }
    }

    &__str {
        margin-right: ac(40px, 25px);
    }

    @mixin max-md {
        .slider-btn {
            order: 1;
        }
    }
}