.stats {
    $gap: var(--gap-sec);
    margin-top: $gap;

    &__title {
        max-width: 20ch;
    }

    &__card-box {
        margin-top: ac(61px, 30px);
        gap: ac(20px, 15px);
        justify-items: center;
    }

    &__card {
        display: flex;
        flex-direction: column;
        width: min(100%, 500px);
        min-height: ac(362px, 250px);
        padding: ac(50px, 25px);
        background-color: var(--green);
        border-radius: ac(40px, 30px);

        &--yellow {
            background-color: var(--yellow);
        }

        &--pink {
            background-color: var(--pink);
        }
    }

    &__descr {
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
        margin-bottom: 20px;
    }

    &__count {
        margin-top: auto;
        text-align: right;
        font-weight: 700;
        font-family: var(--font-sec);
    }

    &__num {
        font-size: ac(85px, 46px);
        line-height: ac(90px, 66px);
    }

    &__symbol {
        font-size: ac(58px, 34px);
        margin-bottom: ac(-20px, 0px);
    }

    @mixin md {
        &__card-box {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}