input, textarea {
    padding: ac(7px, 13px) 0;
    width: 100%;
    border-bottom: 1px solid var(--black);
    border-radius: 0;
    @mixin transparent-bg-input var(--black);

    &,
    &::placeholder {
        color: var(--black);
        font-size: ac(25px, 16px);
        line-height: ac(25px, 20px);
        font-weight: 400;
        transition: .3s ease;
    }

    &::placeholder {
        opacity: 1;
    }

    &:hover {
        border-color: var(--pink);
        &,
        &::placeholder {
            color: var(--pink);
        }
    }
}

textarea {
    padding-top: ac(13px, 18px);
    height: ac(110px, 95px);
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: var(--black) rgba(30, 30, 30, .2);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        background: rgba(30, 30, 30, .2);
        border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--black);
        border-radius: 0;
    }
}

.ready__select {
    .choices {
        &::after {
            display: none;
        }

        &:hover {
            &::before {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath stroke='%23E572DA' stroke-linejoin='round' stroke-width='2' d='M1 1.5 7.5 8 14 1.5'/%3E%3C/svg%3E") center / contain no-repeat;
            }

            .choices__inner {
                border-bottom: 1px solid var(--pink) !important;
            }
            .choices__list--single .choices__item {
                color: var(--pink);
            }
        }
    
        &::before {
            content: '';
            position: absolute;
            top: ac(21px, 18px, 639, 640);
            right: 0;
            width: 15px;
            height: 9px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='round' stroke-width='2' d='M1 1.5 7.5 8 14 1.5'/%3E%3C/svg%3E") center / contain no-repeat;
            transition: .3s ease;
        }
    
        &.is-open {
            &::before {
                transform: rotate(-180deg);
            }
        }
    
        &__inner {
            padding: 11px 0 !important;
            min-height: 48px;
            border-bottom: 1px solid var(--white) !important;
            transition: .3s ease;
            border-radius: 0;
        }
        
        .choices__list {
            width: 100%;
            display: flex;
            flex-direction: column;
    
            &--single {
                .choices__item {
                    color: var(--white);
                    font-size: ac(25px, 16px);
                    line-height: ac(25px, 20px);
                    transition: .3s ease;
                }
            }
    
            &--dropdown {
                border-radius: 0px !important;
                z-index: 20;
                background-color: var(--black);

                &:not(.is-active) {
                    visibility: hidden;
                    opacity: 0;
                }
    
                .choices__item, .choices__input {
                    line-height: 30px;
                }
    
                .choices__item {
                    &::after {
                        display: none;
                    }
                }
            }
    
            & > div {
                width: 100%;
                max-height: 200px;
            }
            
            .simplebar-scrollbar::before {
                background-color: var(--white);
            }
    
        }
    
        .choices__inner,
        .choices__list--single {
            padding: 0;
            padding-bottom: 0 !important;
        }
    
        .choices__inner {
            background-color: var(--black);
            border: 0;
        }
    
        .choices__item--selectable {
            font-size: 15px;
            font-weight: 300;
            color: var(--white);
            line-height: 48px;
            opacity: 1;

            &.is-highlighted {
                color: var(--black)
            }
        }
    }
}

