.benefit {
    $gap: var(--gap-main);
    margin-top: $gap;
    overflow: hidden;

    &__slider {
        margin-top: ac(60px, 30px);
    }

    &__card {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 0 40px 40px 0;

        &--pink {
            background-color: var(--pink);
        }
        &--green {
            background-color: var(--green);
        }
        &--blue {
            background-color: var(--blue);
        }
        &--yellow {
            background-color: var(--yellow);
        }
    }

    &__img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ac(32px, 25px) ac(48px, 35px);
        width: 100%;
        
        flex-shrink: 0;
        background-color: var(--black);
    }

    &__img {
        width: 100%;
        height: 100%;

        img {
            object-fit: contain;
        }
    }

    &__col {
        width: 100%;
        padding: 28px 30px;
        color: var(--white);
    }

    &__name {
        font-size: ac(25px, 20px);
        line-height: ac(29px, 24px);
        font-family: var(--font-sec);
    }

    &__simplebar {
        margin-top: 8px;
        width: 100%;
        max-height: calc(ac(21px, 26px) * 8);

        .simplebar-scrollbar:before {
            background-color: var(--white);
        }
    }

    &__descr {
        padding-right: 10px;
        font-size: 17px;
        line-height: ac(21px, 26px);
    }

    @mixin lg {
        .slider-btn {
            display: none;
        }

        .swiper-wrapper {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
		}

        &__card {
            height: 100%;
        }

        &__img-box {
            max-width: 196px;
            min-height: 179px;
        }
    }

    @mixin max-lg {
        .slider-btn {
            margin-top: 19px;
    
            &.next {
                margin-right: auto;
            }
        }

        &__card {
            flex-direction: column;
            align-items: center;
        }

        &__img-box {
            height: 150px;
        }
    }
}