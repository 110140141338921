.footer {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(90px, 40px) 0;
    background-color: var(--black);

    &__btn-box {
        margin-right: ac(-28px, -8px);
        margin-bottom: ac(-30px, -8px);

        &>* {
            width: auto;
            margin-right: ac(28px, 8px);
            margin-bottom: ac(30px, 8px);
        }
    }

    &__slider {
        margin-top: ac(51px, 40px);
    }

    .slider-btn {
        margin-top: 19px;

        &.next {
            margin-right: auto;
        }
    }

    &__name, &__tel, &__address {
        color: var(--white);
    }

    &__name {
        font-size: ac(25px, 24px);
        line-height: 1em;
        font-weight: 600;
        font-family: var(--font-sec);
    }

    &__tel, &__address {
        transition: .3s ease;

        &:hover {
            color: var(--pink);
        }
    }

    &__tel, &__address {
        font-size: 17px;
        line-height: 22px;
    }

    &__address {
        margin-top: 15px;
    }

    &__tel {
        margin-top: 10px;
    }

    &__bottom {
        margin-top: ac(60px, 44px);
    }

    &__mail {
        a {
            font-size: ac(40px, 28px);
            line-height: ac(40px, 34px);
            font-family: var(--font-sec);
            font-weight: 600;
            color: var(--white);
            transition: .3s ease;

            &:hover {
                color: var(--pink);
            }
        }
    }

    &__logo {
        &>a {
            width: 160px;
            height: 29px;
    
            path {
                transition: .3s ease;
            }
    
            &:hover {
                path {
                    fill: var(--pink);
                }
                path:nth-child(1), path:nth-child(3){
                    fill: var(--blue);
                }
            }
        }
    }

    &__soc-box {
        margin-right: ac(-60px, -20px);
    }

    &__soc {
        margin-right: ac(60px, 30px);
        transition: .3s ease;

        &--fb {
            width: 16px;
            height: 32px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' fill='none'%3E%3Cpath fill='%23fff' d='M4 10.667H0V16h4v16h6.667V16h4.856L16 10.667h-5.333V8.444c0-1.273.256-1.777 1.486-1.777H16V0h-5.077C6.128 0 4 2.11 4 6.153v4.514Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' fill='none'%3E%3Cpath fill='%23E572DA' d='M4 10.667H0V16h4v16h6.667V16h4.856L16 10.667h-5.333V8.444c0-1.273.256-1.777 1.486-1.777H16V0h-5.077C6.128 0 4 2.11 4 6.153v4.514Z'/%3E%3C/svg%3E");
            }
        }

        &--in {
            width: 32px;
            height: 31px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='31' fill='none'%3E%3Cpath fill='%23fff' d='M6.64 3.37c0 1.86-1.48 3.37-3.307 3.37C1.507 6.74.027 5.23.027 3.37S1.507 0 3.333 0C5.16 0 6.64 1.51 6.64 3.37Zm.027 6.065H0V31h6.667V9.435Zm10.642 0h-6.624V31h6.626V19.68c0-6.295 8.038-6.81 8.038 0V31H32V17.345c0-10.62-11.896-10.234-14.69-5.006V9.435Z' /%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='31' fill='none'%3E%3Cpath fill='%23E572DA' d='M6.64 3.37c0 1.86-1.48 3.37-3.307 3.37C1.507 6.74.027 5.23.027 3.37S1.507 0 3.333 0C5.16 0 6.64 1.51 6.64 3.37Zm.027 6.065H0V31h6.667V9.435Zm10.642 0h-6.624V31h6.626V19.68c0-6.295 8.038-6.81 8.038 0V31H32V17.345c0-10.62-11.896-10.234-14.69-5.006V9.435Z' /%3E%3C/svg%3E");
            }
        }

        &--x {
            width: 28px;
            height: 31px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='32' fill='none'%3E%3Cpath fill='%23fff' d='m.07 0 11.076 17.65L0 32h2.509l9.759-12.564L20.152 32h8.538l-11.7-18.642L27.364 0h-2.51L15.87 11.57 8.607 0H.07Zm3.69 2.202H7.68L25 29.798h-3.922L3.759 2.202Z' /%3E%3C/svg%3E");
            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='32' fill='none'%3E%3Cpath fill='%23E572DA' d='m.07 0 11.076 17.65L0 32h2.509l9.759-12.564L20.152 32h8.538l-11.7-18.642L27.364 0h-2.51L15.87 11.57 8.607 0H.07Zm3.69 2.202H7.68L25 29.798h-3.922L3.759 2.202Z' /%3E%3C/svg%3E");
            }
        }

        &--inst {
            width: 32px;
            height: 31px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='31' fill='none'%3E%3Cpath fill='%23fff' d='M16 20.667a5.166 5.166 0 1 1 0-10.334 5.167 5.167 0 0 1 0 10.334Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M16 0c-4.21 0-4.736.018-6.39.093C3.981.351.853 3.475.594 9.109.518 10.764.5 11.29.5 15.5s.018 4.738.093 6.391c.258 5.63 3.382 8.758 9.016 9.016C11.264 30.982 11.79 31 16 31s4.738-.018 6.391-.093c5.624-.258 8.76-3.382 9.015-9.016.076-1.653.094-2.181.094-6.391s-.018-4.736-.093-6.39c-.253-5.624-3.38-8.757-9.015-9.016C20.738.018 20.21 0 16 0Zm0 7.54a7.96 7.96 0 1 0 0 15.92 7.96 7.96 0 0 0 0-15.92Zm-5.167 7.96a5.166 5.166 0 1 0 10.334 0 5.167 5.167 0 0 0-10.334 0Zm11.58-8.273a1.86 1.86 0 1 1 3.722.001 1.86 1.86 0 0 1-3.722-.001Z' clip-rule='evenodd'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='31' fill='none'%3E%3Cpath fill='%23E572DA' d='M16 20.667a5.166 5.166 0 1 1 0-10.334 5.167 5.167 0 0 1 0 10.334Z'/%3E%3Cpath fill='%23E572DA' fill-rule='evenodd' d='M16 0c-4.21 0-4.736.018-6.39.093C3.981.351.853 3.475.594 9.109.518 10.764.5 11.29.5 15.5s.018 4.738.093 6.391c.258 5.63 3.382 8.758 9.016 9.016C11.264 30.982 11.79 31 16 31s4.738-.018 6.391-.093c5.624-.258 8.76-3.382 9.015-9.016.076-1.653.094-2.181.094-6.391s-.018-4.736-.093-6.39c-.253-5.624-3.38-8.757-9.015-9.016C20.738.018 20.21 0 16 0Zm0 7.54a7.96 7.96 0 1 0 0 15.92 7.96 7.96 0 0 0 0-15.92Zm-5.167 7.96a5.166 5.166 0 1 0 10.334 0 5.167 5.167 0 0 0-10.334 0Zm11.58-8.273a1.86 1.86 0 1 1 3.722.001 1.86 1.86 0 0 1-3.722-.001Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }

    @mixin sm {
        &__logo {
            width: 100%;
            margin-top: 40px;
            order: 1;
        }
    }

    @mixin lg {
        .slider-btn {
            display: none;
        }

        .swiper-wrapper {
			width: auto;
            flex-wrap: wrap;
			margin-right: ac(-20px, -10px, 1024, 1440);
            margin-bottom: ac(-40px, -25px, 1024, 1440);
		}

        .swiper-slide {
			width: calc(100% / 6 - ac(20px, 10px, 1024, 1440));
			margin-right: ac(20px, 10px, 1024, 1440);
            margin-bottom: ac(40px, 25px, 1024, 1440);
		}
        
        &__tel, &__address {
            font-size: 14px;
            line-height: 19px;
        }
    }

    @mixin max-sm {
        &__bottom {
            align-items: center;
        }

        &__mail {
            width: 100%;
            margin-bottom: 25px;
            text-align: center;
        }

        &__soc-box {
            margin-right: -8px;
        }

        &__card {
            padding-right: 10px;
        }

        &__soc {
            margin-right: 8px;
        }

        &__soc--fb {
            width: 44px;
            height: 44px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23fff' d='M19.25 18.333H16.5V22h2.75v11h4.583V22h3.339l.328-3.667h-3.667v-1.528c0-.875.176-1.222 1.022-1.222H27.5V11h-3.49c-3.297 0-4.76 1.451-4.76 4.23v3.103Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='M19.25 18.333H16.5V22h2.75v11h4.583V22h3.339l.328-3.667h-3.667v-1.528c0-.875.176-1.222 1.022-1.222H27.5V11h-3.49c-3.297 0-4.76 1.451-4.76 4.23v3.103Z'/%3E%3C/svg%3E");
            }
        }

        &__soc--in {
            width: 44px;
            height: 44px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23fff' d='M15.357 13.391c0 1.321-1.05 2.392-2.346 2.392-1.297 0-2.347-1.07-2.347-2.392 0-1.32 1.05-2.391 2.347-2.391 1.296 0 2.346 1.071 2.346 2.391Zm.02 4.305h-4.732V33h4.731V17.696Zm7.552 0h-4.7V33h4.701v-8.034c0-4.467 5.705-4.832 5.705 0V33h4.72v-9.69c0-7.538-8.442-7.263-10.426-3.553v-2.061Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='M15.357 13.391c0 1.321-1.05 2.392-2.346 2.392-1.297 0-2.347-1.07-2.347-2.392 0-1.32 1.05-2.391 2.347-2.391 1.296 0 2.346 1.071 2.346 2.391Zm.02 4.305h-4.732V33h4.731V17.696Zm7.552 0h-4.7V33h4.701v-8.034c0-4.467 5.705-4.832 5.705 0V33h4.72v-9.69c0-7.538-8.442-7.263-10.426-3.553v-2.061Z'/%3E%3C/svg%3E");
            }
        }

        &__soc--x {
            width: 44px;
            height: 44px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23fff' d='m10.292 11 9.085 12.134L10.235 33h2.057l8.005-8.638L26.763 33h7.003l-9.597-12.817L32.68 11H30.62l-7.371 7.955L17.294 11h-7.002Zm3.026 1.514h3.216L30.74 31.486h-3.216L13.318 12.514Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='m10.292 11 9.085 12.134L10.235 33h2.057l8.005-8.638L26.763 33h7.003l-9.597-12.817L32.68 11H30.62l-7.371 7.955L17.294 11h-7.002Zm3.026 1.514h3.216L30.74 31.486h-3.216L13.318 12.514Z'/%3E%3C/svg%3E");
            }
        }

        &__soc--inst {
            width: 44px;
            height: 44px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23fff' d='M22 26.333a3.833 3.833 0 1 1-.001-7.665A3.833 3.833 0 0 1 22 26.333Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M22 11c-3.123 0-3.514.013-4.74.069-4.177.192-6.498 2.509-6.69 6.69-.057 1.227-.07 1.618-.07 4.741 0 3.123.013 3.515.069 4.742.192 4.176 2.509 6.497 6.69 6.689 1.227.056 1.618.069 4.741.069 3.123 0 3.515-.013 4.742-.069 4.172-.192 6.5-2.509 6.688-6.69.057-1.226.07-1.618.07-4.741 0-3.123-.013-3.514-.069-4.74-.188-4.174-2.508-6.498-6.688-6.69-1.228-.057-1.62-.07-4.743-.07Zm0 5.595a5.905 5.905 0 1 0 0 11.81 5.905 5.905 0 0 0 0-11.81ZM18.167 22.5a3.833 3.833 0 1 0 7.665.001 3.833 3.833 0 0 0-7.665-.001Zm8.591-6.138a1.38 1.38 0 1 1 2.761.001 1.38 1.38 0 0 1-2.76-.001Z' clip-rule='evenodd'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='M22 26.333a3.833 3.833 0 1 1-.001-7.665A3.833 3.833 0 0 1 22 26.333Z'/%3E%3Cpath fill='%23E572DA' fill-rule='evenodd' d='M22 11c-3.123 0-3.514.013-4.74.069-4.177.192-6.498 2.509-6.69 6.69-.057 1.227-.07 1.618-.07 4.741 0 3.123.013 3.515.069 4.742.192 4.176 2.509 6.497 6.69 6.689 1.227.056 1.618.069 4.741.069 3.123 0 3.515-.013 4.742-.069 4.172-.192 6.5-2.509 6.688-6.69.057-1.226.07-1.618.07-4.741 0-3.123-.013-3.514-.069-4.74-.188-4.174-2.508-6.498-6.688-6.69-1.228-.057-1.62-.07-4.743-.07Zm0 5.595a5.905 5.905 0 1 0 0 11.81 5.905 5.905 0 0 0 0-11.81ZM18.167 22.5a3.833 3.833 0 1 0 7.665.001 3.833 3.833 0 0 0-7.665-.001Zm8.591-6.138a1.38 1.38 0 1 1 2.761.001 1.38 1.38 0 0 1-2.76-.001Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }

    @media (max-width: 460px) {
        &__soc-box {
            margin: 20px auto 0;
        }

        &__soc {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__logo {
            width: 100%;
            justify-content: center;
            margin: 0 auto;
        }
    }

    @mixin max-xs {
        &__mail {
            a {
                font-size: 24px;
            }
        }
    }
}