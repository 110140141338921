.map {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding-bottom: ac(110px, 40px);

    &__bg {
        transform: translateX(-50%);
        width: ac(1413px, 537px, 375, 1440);
        height: ac(810px, 307px, 375, 1440);

        &::before {
            content: '';
            position: absolute;
            top: -75px;
            left: 0;
            width: 100%;
            height: 242px;
            background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            
        }
    }

    &__count {
        &:not(:first-child) {
            margin-top: ac(33px, 0px);
        }
    }

    &__num {
        font-size: ac(100px, 42px);
    }

    &__btn-box {
        margin-top: ac(63px, 23px);
    }

    @mixin md {
        padding-top: 62px;

        &__bg {
            width: ac(1413px, 450px, 375, 1440);
            height: ac(810px, 250px, 375, 1440);
            top: 0;
            transform: translateX(calc(-50% + ac(366px, 150px)));

            &::before {
                top: 0;
                width: 515px;
                height: 100%;
                background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            }
        }

        &__btn {
            &:not(:first-child) {
                margin-top: ac(21px, 8px);
            }
        }

        &__pin {
            transform: translate(calc(-50% - ac(57px, 33px)), calc(-50% + ac(65px, 36px)));
            width: ac(746px, 425px);
            height: ac(415px, 250px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='746' height='415' fill='none'%3E%3Cpath fill='%2349D69B' d='M746 188a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM430 61a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM403 21a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM115 72a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z'/%3E%3Cpath fill='%233AD0EF' d='M78 137a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM404 6a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z'/%3E%3Cpath fill='%23E572DA' d='M465 33a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM12 126a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z'/%3E%3Cpath fill='%23F2B532' d='M520 409a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM416 36a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z'/%3E%3C/svg%3E");
        }
    }

    @mixin max-md {
        padding-bottom: ac(520px, 347px, 375, 768);

        &__bg {
            bottom: 0;

            &::before {
                z-index: 1;
            }
        }

        &__btn-box {
            flex-wrap: wrap;
            flex-direction: row;
            margin-right: -10px;
            margin-bottom: -8px;
        }

        &__btn {
            margin-right: 10px;
            margin-bottom: 8px;
        }

        &__pin {
            transform: translate(calc(-50% - ac(32px, 19px, 375, 768)), calc(-50% + ac(37px, 26px, 375, 768)));
            width: ac(480px, 299px, 375, 768);
            height: ac(280px, 168px, 375, 768);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='299' height='168' fill='none'%3E%3Ccircle cx='287' cy='75' r='12' fill='%2349D69B'/%3E%3Ccircle cx='167.5' cy='26.5' r='4.5' fill='%2349D69B'/%3E%3Ccircle cx='156.5' cy='11.5' r='7.5' fill='%2349D69B'/%3E%3Ccircle cx='157.5' cy='5.5' r='5.5' fill='%233AD0EF'/%3E%3Ccircle cx='180' cy='16' r='4' fill='%23E572DA'/%3E%3Ccircle cx='8.5' cy='50.5' r='8.5' fill='%23E572DA'/%3E%3Ccircle cx='201.5' cy='158.5' r='9.5' fill='%23EAC306'/%3E%3Ccircle cx='162' cy='17' r='10' fill='%23EAC306'/%3E%3Ccircle cx='33.5' cy='55.5' r='19.5' fill='%233AD0EF'/%3E%3Ccircle cx='47.5' cy='30.5' r='16.5' fill='%2349D69B'/%3E%3C/svg%3E");
        }
    }

    @mixin max-sm {
        &__btn {
            &:not(:nth-child(1), :nth-child(2)) {
                width: calc(100% / 2 - 10px);
            }
        }
    }
}