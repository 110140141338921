.resources {
    $gap: var(--gap-main);
    margin-top: $gap;

    &__slider {
        margin-top: ac(60px, 25px);
    }

    &__card {
        position: relative;
        background-color: var(--black);
        padding: 20px;
        height: 100%;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--black);
            opacity: .4;
        }

        &--video {
            background-color: transparent;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: ac(56px, 40px);
                height: ac(56px, 40px);
                border-radius: 0;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23fff' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat;
                transition: .3s ease;

                &:hover {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23E572DA' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }

            .resources__simplebar {
                margin-top: auto;
                margin-bottom: 0;
            }

            .resources__category {
                margin-top: 0;
            }

            &:hover {
                &::after {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47' height='55' fill='none'%3E%3Cpath fill='%23E572DA' d='M46.5 26.634a1 1 0 0 1 0 1.732L2.25 53.914a1 1 0 0 1-1.5-.866V1.952a1 1 0 0 1 1.5-.866L46.5 26.634Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }

        &:hover {
            .resources {
                &__name, &__category, &__tag  {
                    color: var(--pink);
                }
            }
        }
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__img {
        position: relative;
        min-width: calc(100% + 40px);
        height: ac(320px, 250px);
        margin-top: -20px;
        margin-left: -20px;
        margin-bottom: 21px;
    }

    &__name, &__category, &__tag  {
        color: var(--white);
        transition: .3s ease;
    }

    &__simplebar {
        margin-bottom: auto;
        width: 100%;
        max-height: calc(4 * ac(24px, 26px));
        z-index: 1;

        .simplebar-scrollbar::before {
            background-color: var(--white);
        }
    }

    &__name {
        font-family: var(--font-sec);
        padding-right: 10px;
    }

    &__category {
        margin-top: 21px;
        opacity: .5;
        font-size: ac(17px, 15px);
        line-height: 21px;
        position: relative;
        z-index: 1;

        &:empty {
            margin-top: 0;
        }
    }

    &__tag {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: var(--black);
        border-radius: 24px;
        padding: 0 ac(20px, 15px);
        font-weight: 700;
        font-size: ac(16px, 14px);
        line-height: ac(39px, 36px);
        z-index: 11;
        isolation: isolate;
    }

    @mixin md {
        .slider-btn {
            margin-top: 22px;
        }
    }

    @mixin max-md {
        .slider-btn {
            order: 1;
            margin-top: 16px;
        }

        &__card--video {
            &::after {
                width: 29px;
                height: 34px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='34' fill='none'%3E%3Cpath fill='%23fff' d='M28.5 16.134a1 1 0 0 1 0 1.732l-27 15.589a1 1 0 0 1-1.5-.866V1.412A1 1 0 0 1 1.5.546l27 15.588Z'/%3E%3C/svg%3E") center / contain no-repeat;
                transition: unset;

                &:hover {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='34' fill='none'%3E%3Cpath fill='%23E572DA' d='M28.5 16.134a1 1 0 0 1 0 1.732l-27 15.589a1 1 0 0 1-1.5-.866V1.412A1 1 0 0 1 1.5.546l27 15.588Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }
    }
}