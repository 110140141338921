.btn {
    display: inline-flex;
    padding: 0 ac(20px, 19px);
    border: 1px solid var(--pink);
    border-radius: 23px;

    font-family: var(--font-sec);
    font-weight: 700;
    font-size: ac(16px, 14px);
    line-height: ac(44px, 38px);
    justify-content: center;
    transition: .3s ease;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background-color: var(--pink);
        color: var(--white);
    }
    
    &--wh {
        border-color: var(--white);
        background-color: var(--white);
    }

    @mixin max-sm {
        width: 100%;
    }

    @mixin max-xs {
        padding: 0 10px;
        font-size: 13px;
    }
}

.slider-btn {
    width: 27px;
    height: 47px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: .3s ease;
    user-select: none;

    &:hover {
        &.prev {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='47' fill='none'%3E%3Cpath stroke='%23E572DA' stroke-linejoin='round' stroke-width='5' d='M24.5 2 3 23.5 24.5 45'/%3E%3C/svg%3E");
        }
    
        &.next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='47' fill='none'%3E%3Cpath stroke='%23E572DA' stroke-linejoin='round' stroke-width='5' d='m2.75 2 21.5 21.5L2.75 45'/%3E%3C/svg%3E");
        }
    }

    &.swiper-button-lock {
        display: none !important;
    }
    
    &.swiper-button-disabled {
        pointer-events: none;
        opacity: .5;
    }

    &.prev {
        margin-left: auto;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='47' fill='none'%3E%3Cpath stroke='%231E1E1E' stroke-linejoin='round' stroke-width='5' d='M24.5 2 3 23.5 24.5 45'/%3E%3C/svg%3E");
    }

    &.next {
        margin-left: 40px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='47' fill='none'%3E%3Cpath stroke='%231E1E1E' stroke-linejoin='round' stroke-width='5' d='m2.75 2 21.5 21.5L2.75 45'/%3E%3C/svg%3E");
    }

    &--wh {
        &.prev {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='47' fill='none'%3E%3Cpath stroke='%23FFF' stroke-linejoin='round' stroke-width='5' d='M24.5 2 3 23.5 24.5 45'/%3E%3C/svg%3E");
        }
    
        &.next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='47' fill='none'%3E%3Cpath stroke='%23FFF' stroke-linejoin='round' stroke-width='5' d='m2.75 2 21.5 21.5L2.75 45'/%3E%3C/svg%3E");
        }
    }

    @mixin md {
        margin-right: -4px;
    }

    @mixin max-md {
        width: 19px;
        height: 32px;
        
        &.prev {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='32' fill='none'%3E%3Cpath fill='%231E1E1E' fill-rule='evenodd' d='M1.144 14.784a1.72 1.72 0 0 0 0 2.432L15.928 32l2.431-2.431L4.792 16 18.36 2.431 15.928 0 1.144 14.784Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    
        &.next {
            margin-right: auto;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='32' fill='none'%3E%3Cpath fill='%231E1E1E' fill-rule='evenodd' d='M17.856 14.784a1.72 1.72 0 0 1 0 2.432L3.07 32l-2.43-2.431L14.208 16 .64 2.431 3.071 0l14.785 14.784Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    
        &--wh {
            &.prev {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='32' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M1.144 14.784a1.72 1.72 0 0 0 0 2.432L15.928 32l2.431-2.431L4.792 16 18.36 2.431 15.928 0 1.144 14.784Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        
            &.next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='32' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M17.856 14.784a1.72 1.72 0 0 1 0 2.432L3.07 32l-2.43-2.431L14.208 16 .64 2.431 3.071 0l14.785 14.784Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        }

        &:hover {
            &.prev {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='32' fill='none'%3E%3Cpath fill='%23E572DA' fill-rule='evenodd' d='M1.144 14.784a1.72 1.72 0 0 0 0 2.432L15.928 32l2.431-2.431L4.792 16 18.36 2.431 15.928 0 1.144 14.784Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        
            &.next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='32' fill='none'%3E%3Cpath fill='%23E572DA' fill-rule='evenodd' d='M17.856 14.784a1.72 1.72 0 0 1 0 2.432L3.07 32l-2.43-2.431L14.208 16 .64 2.431 3.071 0l14.785 14.784Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }
}