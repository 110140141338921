.gallery {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 41px) 0 ac(110px, 60px);
    background-color: var(--black);

    &__text-box {
        color: var(--white);
    }

    &__descr {
        margin-top: ac(51px, 20px);
        font-size: 17px;
        line-height: ac(21px, 26px);

        &>* {
            &:not(:first-child) {
                margin-top: ac(10px, 5px);
            }
        }
    }

    &__img {
        width: 100%;
    }

    @mixin sm {
        &__gallery {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 1.2fr .8fr;
            grid-template-rows: 1.3fr .7fr .6fr 1.4fr;
        }

        &__img {
            &:nth-child(1) {
                grid-row: span 2;
            }
            &:nth-child(2) {
                grid-row: span 3;
            }
            &:nth-child(3) {
                grid-row: span 2;
            }
            &:nth-child(4) {
                grid-row: span 1;
            }
        }
    }

    @mixin lg {
        &__text-box {
            max-width: ac(470px, 350px);
            width: 100%;
            flex-shrink: 0;
            margin-right: ac(41px, 20px);
        }

        &__wrapper {
            display: flex;
        }

        &__title {
            max-width: 304px;
        }

        &__gallery {
            grid-template-columns: ac(441px, 280px, 1024, 1440) ac(304px, 250px, 1024, 1440);
            grid-template-rows: ac(152px, 110px, 1024, 1440) 69px 64px ac(138px, 100px, 1024, 1440);
        }
    }

    @mixin max-lg {
        &__gallery {
            margin-top: 25px;
        }
    }

    @mixin max-sm {
        &__gallery {
            max-width: 400px;
            margin: 25px auto 0;
        }

        &__img {
            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    }
}