.contact {
    $gap: var(--gap-main);
    padding-top: $gap;

    &__decor {
        position: absolute;
        bottom: ac(-303px, -217px);
        left: 50%;
        transform: translateX(calc(-50% - ac(648px, 430px)));
        height: 427px;
        width: 670px;
        z-index: -1;

        svg {
            object-fit: contain;
        }
    }
 
    &__form {
        margin-top: ac(36px, 17px);

        input, .ready__select {
            margin-bottom: ac(48px, 13px, 375, 1440);
        }

        .ready__select {
            .choices {
                &:hover {
                    &::before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath stroke='%23E572DA' stroke-linejoin='round' stroke-width='2' d='M1 1.5 7.5 8 14 1.5'/%3E%3C/svg%3E") center / contain no-repeat;
                    }

                    .choices__inner {
                        border-bottom: 1px solid var(--pink) !important;
                    }

                    .choices__list--single .choices__item {
                        color: var(--pink);
                    }
                }

                &::before {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath stroke='%23000' stroke-linejoin='round' stroke-width='2' d='M1 1.5 7.5 8 14 1.5'/%3E%3C/svg%3E") center / contain no-repeat;
                }


                &__inner {
                    border-bottom: 1px solid var(--black) !important;
                }

                .choices__list {
                    &--single {
                        .choices__item {
                            color: var(--black);
                        }
                    }

                    &--dropdown {
                        background-color: var(--white);
                    }
                    .simplebar-scrollbar::before {
                        background-color: var(--black);
                    }

                }

                .choices__inner {
                    background-color: var(--white);
                    border: 0;
                }

                .choices__item--selectable {
                    color: var(--black);

                    &.is-highlighted {
                        color: var(--black);
                    }
                }
            }
        }

    }

    &__subtitle {
        margin-top: ac(30px, 15px);
    }

    &__btn {
        margin-top: ac(41px, 31px);
        padding: 0 19px;
    }

    .choices__list.choices__list--dropdown {
        .choices__item:first-child {
            display: none;
        }
    }
   
    @mixin sm {
        &__form {
            margin-right: -20px;

            &>* {
                margin-right: 20px;
            }

            input, .ready__select {
                flex: max(200px, 100% / 2 - 20px);
            }
        }
    }

    @mixin md {
        &__title-box {
            flex-shrink: 0;
            margin-right: ac(123px, 40px);
            max-width: 310px;
        }
    }

    @mixin max-md {
        &__wrapper {
            flex-direction: column;
        }

        &__decor {
            display: none;
        }
    }
}