.impact {
    $gap: var(--gap-md);
    margin-top: $gap;
    overflow: hidden;

    &__wrapper {
        padding: 40px ac(50px, 20px);
    }

    &__bg {
        &, &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
       
        z-index: -1;

        &::before {
            content: '';
            opacity: 0.6;
            background: linear-gradient(180deg, var(--black) 0%, var(--black) 100%);
        }
    }
    &__bottom, &__title {
        color: var(--white);
    }

    &__title {
        margin-bottom: ac(40px, 20px);
    }

    &__subtitle {
        margin-bottom: 10px;
    }

    &__descr {
        &>*, &:not(:last-child) {
             margin-bottom: 5px;
        }

        font-size: 17px;
        line-height: 21px;
    }

    &__text {
        margin-top: 20px;
    }

    &__btn {
        margin-top: ac(26px, 15px);
    }

    @mixin md {
        &__col {
            margin-bottom: 12px;
            margin-right: 20px;
            max-width: ac(470px, 380px);
        }
    }
}