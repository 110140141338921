.projects {
    $gap: var(--gap-main);
    margin-top: $gap;
    background-color: var(--black);
    padding: ac(110px, 49px) 0 ac(110px, 56px);

    &__title, &__name, &__btn {
        color: var(--white);
    }

    &__title {
        margin-bottom: 20px;
    }

    &__name {
        margin-bottom: ac(50px, 25px);
        width: 100%;
        font-size: ac(40px, 28px);
        line-height: ac(40px, 34px);
        font-family: var(--font-sec);
        font-weight: 600;

        &:not(:first-child) {
            margin-top: ac(79px, 35px);
        }
    }

    &__item-box {
        display: flex;
        flex-wrap: wrap;
        gap: ac(59px, 20px) 20px;
    }

    &__item {
       width: calc(100% / 2 - 10px);
       display: flex;
       flex-direction: column;
    }

    &__logo-box {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: ac(26px, 15px);
        border-bottom: 1px solid var(--white);
        height: 100%;
        gap: ac(20px, 15px);
    }

    &__logo, &__flag {
        img {
            object-fit: contain;
        }
    }

    &__logo {
        max-width: ac(184px, 120px);
        max-height: 47px;

        img {
            object-position: center;
            object-fit: contain;
            filter: brightness(0) invert(1);
        }
    }

    &__flag {
        margin-top: ac(13px, 5px);
        width: 25px;
        height: 17px;
    }

    &__btn {
        margin-top: auto;
        margin-left: auto;
    }

    @mixin md {
        &__wrapper {
            display: flex;
        }

        &__title {
            width: 100%;
            max-width: ac(306px, 160px);
            margin-right: ac(126px, 25px);
            flex-shrink: 0;
        }
    }

    @mixin max-sm {
        &__logo {
            max-width: 150px;
            margin: 0 auto;
        }
    }
}