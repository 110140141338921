.about {
    $gap: var(--gap-sec);
    margin-top: $gap;

    &__decor {
        top: ac(-20px, -130px, 375, 1440);
        transform: translateX(calc(-50% + ac(160px, 300px, 375, 1440)));
        width: ac(1419px, 860px, 375, 1440);
        height: ac(905px, 554px, 375, 1440);

        img {
            object-fit: contain;
        }
    }

    &__title {
        margin-top: -1px;
    }

    &__subtitle {
        margin-top: ac(31px, 23px);
    }

    &__card-box {
        margin-top: ac(60px, 40px);
    }

    &__img {
        margin-bottom: ac(30px, 12px);
        width: 100%;
        height: ac(542px, 249px, 375, 640);
        max-height: 62vh;
    }

    &__name {
        margin-bottom: ac(21px, 6px);
        font-size: ac(40px, 32px);
        line-height: ac(40px, 48px);
        font-weight: 600;
        font-family: var(--font-sec);
    }

    &__descr {
        margin-bottom: ac(30px, 16px);
        font-size: 17px;
        line-height: ac(21px, 26px);
    }

    .slider-btn {
        margin-top: 19px;
    }

    .slider-btn.next {
        margin-right: auto;
    }

    @mixin lg {
        &__subtitle {
            max-width: 520px;
        }

        .btn {
            padding: 0 ac(20px, 10px, 1024, 1440);
            font-size: ac(16px, 14px, 1024, 1440);
        }
    }

}