@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Raleway:wght@600;700&display=swap');

:root {
  --white: #FFFFFF;
  --black: #1E1E1E; 
  --blue: #3AD0EF;  
  --yellow: #F2B532;
  --green: #49D69B;
  --pink: #E572DA;

  --font-main: 'DM Sans', sans-serif;
  --font-sec: 'Raleway', sans-serif;
  --vh: calc(100vh / 100);

  --gap-main: ac(130px, 60px);
  --gap-sec: ac(120px, 50px);
  --gap-md: ac(112px, 45px);
  --gap-none: 0;
}