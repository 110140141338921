.ready {
    $gap: var(--gap-main);
    margin-top: $gap;
    padding: ac(110px, 49px) 0 ac(110px, 56px);
    background-color: var(--black);

    &+.footer {
        margin-top: 0;
    }

    &__text-box {
        color: var(--white);
    }

    &__wrapper {
        &>h3 {
            text-align: center;
            color: var(--white);
        }
    }

    &__subtitle {
        margin-top: 50px;
        font-size: ac(25px, 20px);
        line-height: 1em;
        font-weight: 600;
        font-family: var(--font-sec);
    }

    &__descr {
        margin-top: 10px;
        font-size: 17px;
        line-height: 21px;
    }

    .choices__list.choices__list--dropdown {
        .choices__item:first-child {
            display: none;
        }
    }

    &__form {
        margin-top: ac(36px, 17px);

        input, textarea {
            border-bottom: 1px solid var(--white);
            @mixin transparent-bg-input var(--white);
            background-color: var(--black);

            &,
            &::placeholder {
                color: var(--white);
            }

            &:hover {
                border-color: var(--pink);
                &,
                &::placeholder {
                    color: var(--pink);
                }
            }
        }

        input {
            margin-bottom: ac(49px, 13px, 375, 1440);
        }

        textarea {
            scrollbar-color: var(--white) rgba(255, 255, 255, .2);
            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, .2);
            }
            &::-webkit-scrollbar-thumb {
                background: var(--white);
            }
        }
    }

    &__upload-box, &__select {
        width: 100%;
        margin-bottom: ac(19px, 13px, 375, 1440);
    }

    &__upload-btn {
		position: relative;
        display: flex;
        flex-direction: column;
		align-items: center;
        cursor: pointer;


        input {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}

		div {
			border-radius: 24px;
			padding: 0 ac(19px, 10px);
            font-size: 16px;
			line-height: 45px;
			font-weight: 700;
            font-family: var(--font-sec);
			border: 1px solid var(--white);
			color: var(--white);
			transition: .3s ease;
		}

		&:hover {
			div {
				background-color: var(--pink);
			}

			.footer__upload-text {
				color: var(--pink);
			}
		}
    }

    &__upload-box {
        display: flex;
        align-items: baseline;

        &>span:first-child {
            transform: translateY(3px);
            color: var(--white);
            font-size: ac(25px, 16px);
            line-height: ac(25px, 20px);
            margin-right: auto;
        }
	}

	&__upload-text {
        margin-top: 5px;
		font-size: 16px;
		color: var(--white);
	}

    &__btn {
        margin-top: ac(40px, 31px);
        color: var(--white);
        padding: 0 19px;
    }

    @mixin sm {
        &__form {
            margin-right: -20px;

            &>* {
                margin-right: 20px;
            }

            input {
                flex: max(200px, 100% / 2 - 20px);
            }
        }

        &__select, &__upload-box {
            margin-right: 20px;
            flex: max(200px, 100% / 2 - 20px);
        }
    }

    @mixin md {
        &__wrapper {
            display: flex;
        }

        &__text-box {
            flex-shrink: 0;
            margin-right: ac(123px, 40px);
            max-width: 310px;
        }
    }
}