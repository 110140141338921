.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    z-index: 300;
    transition: .3s ease;

    &__wrapper {
		display: flex;
		align-items: center;
		padding: ac(50px, 12px) 0;
		transition: .3s ease;
	}

	select {
		display: none;
	}

	&.sticky {
		background-color: var(--white);

		.header__wrapper {
			padding: 0;
		}

		.header__logo {
			path {
				fill: var(--black)
			}

			&:hover {
				path {
					fill: var(--blue);
				}
			}
		}
	}

    &__logo {
        width: ac(196px, 174px);
        height: ac(36px, 31px);
		margin-right: auto;
		flex: 0 0 auto;
        z-index: 3;

		path {
			transition: .3s ease;
		}

		&:hover {
			path {
				fill: var(--blue);
			}
			path:nth-child(5), path:nth-child(7){
				fill: var(--pink);
			}
		}
    }

    &__navbar {
		display: flex;
    }

	&__link {
		font-family: var(--font-sec);
		transition: .3s ease;
	
		&:hover, &.active {
			color: var(--pink)
		}
	}

	&__lang-box {
		.choices {
			margin-bottom: 0;
			margin-right: 0;
			transition: .3s ease;
		  
			&:hover {
			  &::before {
				border-color: var(--pink);
			  }
			}
		  
			&::after {
			  display: none;
			}
		  
			&::before {
			  content: '';
			  position: absolute;
			  right: 0px;
			  top: 11px;
			  width: 6px;
			  height: 6px;
			  border-bottom: 1px solid var(--black);
			  border-right: 1px solid var(--black);
			  transform: rotate(45deg);
			  transition: .3s ease;
			}
		  
			&.is-open {
			  &::before {
				top: 16px;
				transform: rotate(-135deg);
			  }
			}
		  }
		  
		  .choices__inner {
			display: flex;
			align-items: center;
			background-color: var(--white);
			padding: 0px !important;
			border: 0 !important;
			border-radius: 0 !important;
			min-height: 34px !important;
		  }
		  
		  .choices__list--single, .choices__list--dropdown {
			[data-value='uk'] {
			  &::before {
				background: url(../../public/images/uk.png) center/contain no-repeat;
			  }
			}
		  
			[data-value='usa'] {
			  &::before {
				background: url(../../public/images/usa.png) center/contain no-repeat;
			  }
			}
		  
			[data-value='roi'] {
			  &::before {
				background: url(../../public/images/roi.png) center/contain no-repeat;
			  }
			}
		  }
		  .choices__list--single {
			padding: 0 19px 0 0 !important;

			[data-value='uk'], [data-value='usa'], [data-value='roi']  {
			  &::before {
				content: '';
				width: 14px;
				height: 14px;
				margin-right: 8px;
				margin-bottom: 2px;
			  }
			}
		  
			.choices__item {
			  display: flex;
			  align-items: center;
			  color: var(--black);
			  font-size: 16px;
			  font-weight: 600;
			  font-family: var(--font-sec);
			}
		  }
		  
		  .choices__list--dropdown {
			width: 80px !important;
			border: 0 !important;
			border-radius: 0 !important;
			background-color: var(--white) !important;
		  
			[data-value='uk'], [data-value='usa'], [data-value='roi'] {
			  &::before {
				content: '';
				margin-right: 10px;
				width: 24px;
				height: 15px;
			  }
			}
		  
			.choices__item--selectable {
			  padding: 0 5px !important;
			  display: flex;
			  align-items: center;
			  color: var(--black) !important;
			  font-weight: 500;
		  
			  &::after {
				display: none;
			  }
		  
			  img {
				margin-right: 10px;
				width: 24px;
				height: 15px;
			  }
		  
			  &.is-highlighted {
				background-color: var(--pink) !important;
			  }
		  
			  &:hover {
				background-color: var(--pink) !important;
			  }
		  
			  &.is-selected {
				display: none;
			  }
			}
		  }
	}

	&__lang {
		margin-right: 35px;
		width: 65px;
		background-color: var(--blue);
	}

	.js-dropdown {
		position: relative;
		z-index: 2;

		&:last-child {
			.header__link {
				margin-right: 0;
			}
		}

		&.open {
			&>.header__link span {
				transform: translateY(1px) rotate(-135deg);
				border-color: var(--pink);
			}

			.header__dropdown {
				max-height: 400px;
				opacity: 1;
				visibility: visible;
				overflow: hidden;
			}

			&>.header__link {
				color: var(--pink);
			}
		}
		
		&>.header__link {
			cursor: pointer;
			position: relative;
			display: inline-flex;
			align-items: center;

			&>span {
				margin-left: 7px;
				height: 6px;
				width: 6px;
				transform: translateY(-1px) rotate(45deg);
				border-bottom: 1px solid var(--black);
				border-right: 1px solid var(--black);
				transition: .3s ease;
			}

			&:hover {
				&>span {
					border-color: var(--pink);
				}
			}
		}
	}
	.simplebar-scrollbar {

		&::before {
			background: var(--pink);
		}
	}
	&__dropdown {
		min-width: 100%;
		max-width: calc(100vw - 15px);
		width: auto;
		position: absolute;
		right: 0px;
		bottom: -1px;
		transform: translateY(100%);
		transform-origin: 0 0;
		border: 2px solid var(--white);
		overflow: hidden;
		visibility: hidden;
		background-color: var(--white);
		transition: .5s ease-in-out;
		max-height: 0px;

		&>div {
			padding: 10px;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.header__link {
			margin-left: 0;
			margin-right: 0;
			white-space: nowrap;
			line-height: 1.5em;

			&:before {
				display: none;
			}

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	@media (min-width: 1025px) {
		&__navbar-box {
			width: calc(100% - 300px);
		}

		&__simplebar {
			max-height: 56px;

			&>.simplebar-wrapper>.simplebar-mask {
				&, &>.simplebar-offset>.simplebar-content-wrapper {
					overflow: visible !important;
				}

				&>.simplebar-offset>.simplebar-content-wrapper>.simplebar-content {
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		
			&>.simplebar-track {
				display: none !important;
			}
		}

		&__burger-box, &__soc-box, &__bg {
			display: none;
		}

		&__navbar {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				left: -40px;
				top: 0;
				width: calc(100% + 60vw);
				height: 100%;
				background-color: var(--white);
				z-index: -1;
				border-radius: 28px;
			}
		}

		&__link {
			font-size: 16px;
			line-height: 56px;
			font-weight: 700;
			margin-right: ac(41px, 30px, 1024, 1440);

			&--btn {
				margin-right: ac(29px, 17px, 1024, 1440);
			}
		}

		&__btn.btn {
	 		flex-shrink: 0;
			padding: 0 27px;

			span {
				line-height: 40px;
				font-size: 13px;
			}
		 }
	}

	@media not all and (min-width: 1025px) {
		&.active {
			&.sticky {
				.header__logo {
					path {
						fill: var(--white);
					}
					path:nth-child(5), path:nth-child(7){
						fill: var(--white);
					}

					&:hover {
						path {
							fill: var(--blue);
						}
						path:nth-child(5), path:nth-child(7){
							fill: var(--pink);
						}
					}
				}
			}

			.header__navbar-box {
			  left: 0;
			  opacity: 1;
			  visibility: visible;
			}
	
			.header__simplebar {
			   transform: translateX(0);
			   opacity: 1;
			}

			.header__lang-box {
				opacity: 1;
				visibility: visible;
			}
	  
			.header__burger {
				span {
					width: 32px;
					background-color: var(--black);
	
					&:nth-child(2) {
						width: 0;
						opacity: 0;
					}
			
					&:nth-child(1) {
						transform: rotate(45deg) translate(7px, 4px);
					}
			
					&:nth-child(3) {
						transform: rotate(-45deg) translate(8px, -6px);
					}
				}
			}
		}

		.js-dropdown {
			text-align: center;

			&.open {
				&>.header__link span {
					margin-top: 9px;
				}
			}

			&>.header__link {
				margin-top: 2.5vh;

				&>span {
					height: 16px;
					width: 16px;
					border-bottom: 2px solid var(--white);
					border-right: 2px solid var(--white);
					margin-left: 15px;
					margin-top: -3px;
				}
			}
		}

		&__dropdown {
			position: relative;
			transform: none;
			left: 0;
			bottom: 2px;
			background: var(--black);

			.header__link {
				font-size: 16px;
				margin-top: 0 !important;
				color: var(--white);
				text-align: center;

				&:hover {
					color: var(--pink);
				}
			}

			&>div {
				align-items: center;
			}
		}

		&__bg {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(30, 30, 30, .9);
			}
		}

		&__lang-box {
			position: fixed;
			top: 91px;
			right: 22px;
			z-index: 3;
			opacity: 0;
			visibility: hidden;

			.choices:before {
				top: 9px;
				width: 9px;
				height: 9px;
				border-bottom: 2px solid var(--white);
				border-right: 2px solid var(--white);
			}

			.choices__inner {
				background-color: transparent;
			}

			.choices__list--single .choices__item {
				color: var(--white);
				font-size: 18px;
			}
		}

		&__burger-box {
			position: relative;
			height: 56px;
			padding-left: 16px;
			z-index: 2;
			cursor: pointer;

			&>span {
				font-size: 16px;
				font-weight: 700;
				font-family: var(--font-sec);
				transition: .3s ease;
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: calc(100% + 50px);
				height: 100%;
				background-color: var(--white);
				border-radius: 61px 0 0 61px;
				z-index: -1;
			}

			&:hover {
				.header__burger {
					span {
						background-color: var(--pink);
					}
				}

				&>span {
					color: var(--pink);
				}
			}
		}

		&__burger {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 37px;
			margin-right: 12px;
			transition: .3s ease;
			z-index: 4;
			cursor: pointer;
	
			span {
				pointer-events: none;
				width: 26px;
				height: 2px;
				background-color: var(--black);
				transition: 0.3s ease;
	
				&:nth-child(2) {
					margin: 7px 0;
					transform: translateX(11px);
				}

				&:nth-child(3) {
					transform: translateX(4px);
				}
			}

		}

		&__navbar-box {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 100%;
			height: calc(var(--vh) * 100);
			padding: 130px 0px 57px;
			
			transition: .3s ease;
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease;
			z-index: 2;
		}

		&__simplebar {
			margin-top: auto;
			max-height: calc(100% - 20px);
			width: 100%;
		}

		&__navbar {
			width: 100%;
			flex-direction: column;
			align-items: center;
		}

		&__link {
			font-weight: 600;
			font-size: 22px;
			color: var(--white);
			margin: 2.5vh 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__soc-box {
			margin-top: auto;
		}

		&__soc {
			margin: 0 2px;

			width: 44px;
			height: 44px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transition: .3s ease;

			&--f {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='44' fill='none'%3E%3Cpath fill='%23fff' d='M19.75 18.333H17V22h2.75v11h4.583V22h3.339L28 18.333h-3.667v-1.528c0-.875.176-1.222 1.022-1.222H28V11h-3.49c-3.297 0-4.76 1.451-4.76 4.23v3.103Z'/%3E%3C/svg%3E");

				&:hover {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='M19.75 18.333H17V22h2.75v11h4.583V22h3.339L28 18.333h-3.667v-1.528c0-.875.176-1.222 1.022-1.222H28V11h-3.49c-3.297 0-4.76 1.451-4.76 4.23v3.103Z'/%3E%3C/svg%3E");
				}
			}

			&--in {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='44' fill='none'%3E%3Cpath fill='%23fff' d='M15.857 13.391c0 1.321-1.05 2.392-2.346 2.392s-2.347-1.07-2.347-2.392c0-1.32 1.05-2.391 2.347-2.391 1.296 0 2.347 1.071 2.347 2.391Zm.02 4.305h-4.732V33h4.731V17.696Zm7.552 0h-4.7V33h4.701v-8.034c0-4.467 5.705-4.832 5.705 0V33h4.72v-9.69c0-7.538-8.442-7.263-10.426-3.553v-2.061Z'/%3E%3C/svg%3E");

				&:hover {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='M15.857 13.391c0 1.321-1.05 2.392-2.346 2.392s-2.347-1.07-2.347-2.392c0-1.32 1.05-2.391 2.347-2.391 1.296 0 2.347 1.071 2.347 2.391Zm.02 4.305h-4.732V33h4.731V17.696Zm7.552 0h-4.7V33h4.701v-8.034c0-4.467 5.705-4.832 5.705 0V33h4.72v-9.69c0-7.538-8.442-7.263-10.426-3.553v-2.061Z'/%3E%3C/svg%3E");
				}
			}

			&--x {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='44' fill='none'%3E%3Cpath fill='%23fff' d='m10.792 11 9.085 12.134L10.734 33h2.058l8.004-8.638L27.263 33h7.002L24.67 20.183 33.179 11H31.12l-7.371 7.955L17.794 11h-7.002Zm3.026 1.514h3.216L31.24 31.486h-3.217L13.818 12.514Z'/%3E%3C/svg%3E");

				&:hover {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='44' fill='none'%3E%3Cpath fill='%23E572DA' d='m10.792 11 9.085 12.134L10.734 33h2.058l8.004-8.638L27.263 33h7.002L24.67 20.183 33.179 11H31.12l-7.371 7.955L17.794 11h-7.002Zm3.026 1.514h3.216L31.24 31.486h-3.217L13.818 12.514Z'/%3E%3C/svg%3E");
				}
			}
		}
	}
}