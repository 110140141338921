.positions {
    $gap: var(--gap-main);
    margin-top: $gap;

    &__form {
        margin-top: ac(69px, 30px);

        .ready__select {
            flex: unset;
            margin-right: 0;
            margin-bottom: 0;

            .choices {
                &:hover {
                    &::before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath stroke='%23E572DA' stroke-linejoin='round' stroke-width='2' d='M1 1.5 7.5 8 14 1.5'/%3E%3C/svg%3E") center / contain no-repeat;
                    }

                    .choices__inner {
                        border-bottom: 1px solid var(--pink) !important;
                    }

                    .choices__list--single .choices__item {
                        color: var(--pink);
                    }
                }

                &::before {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath stroke='%23000' stroke-linejoin='round' stroke-width='2' d='M1 1.5 7.5 8 14 1.5'/%3E%3C/svg%3E") center / contain no-repeat;
                }


                &__inner {
                    border-bottom: 1px solid var(--black) !important;
                }

                .choices__list {
                    &--single {
                        .choices__item {
                            color: var(--black);
                        }
                    }

                    &--dropdown {
                        background-color: var(--white);
                    }
                    .simplebar-scrollbar::before {
                        background-color: var(--black);
                    }

                }

                .choices__inner {
                    background-color: var(--white);
                    border: 0;
                }

                .choices__item--selectable {
                    color: var(--black);

                    &.is-highlighted {
                        color: var(--black);
                    }
                }
            }
        }
    }

    &__btn {
        margin-top: ac(60px, 20px);
    }

    &__col {
        padding-top: 34px;
    }

    &__job {
        &:not(:last-child) {
            margin-bottom: ac(30px, 15px);
        }
        width: 100%;
        padding-bottom: ac(19px, 10px);
        border-bottom: 1px solid var(--black);
        transition: .3s ease;

        &:hover {
            .positions__name, .positions__location {
                color: var(--pink);
            }
            border-bottom: 1px solid var(--pink);
        }
    }

    &__name {
        font-size: ac(40px, 18px, 320, 1440);
        line-height: ac(40px, 22px, 320, 1440);
        font-family: var(--font-sec);
        font-weight: 600;
        transition: .3s ease;
    }

    &__location {
        margin-top: ac(14px, 5px);
        transition: .3s ease;
    }

    &__slider {
        min-height: 100px;
        height: ac(490px, 300px, 320, 1440);
        transition: .3s ease;
        overflow: hidden !important;
    }

    .slider-btn {
        margin-top: 19px;
        transform: rotate(90deg);
    }

    .slider-btn.next {
        margin-right: auto;
    }

    .choices__list.choices__list--dropdown {
        .choices__item:first-child {
            display: none;
        }
    }

    @mixin md {
        &__wrapper {
            display: flex;
        }

        &__form-box {
            flex-shrink: 0;
            max-width: ac(412px, 250px);
            margin-right: ac(128px, 25px);

            input, .ready__select {
                &:not(:first-child) {
                    margin-top: ac(49px, 15px);
                }
            }
        }
    }

    @mixin max-md {
        &__form {
            justify-content: space-between;

            .ready__select,
            input {
                width: 48%;
            }
        }
    }

    @mixin max-sm {
        &__form {
            .ready__select,
            input {
                width: 100%;

                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }
}